import React, { Component } from 'react';
import { EditorContext } from "../page-editor";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";
import { paths } from "../../router";
import { commands } from "../../lib/inline-editor";
import { FRONTEND_DATA } from "../../globals";
import { HotKey } from "../ui-kit";
import { Formik, Field } from 'formik';
import FormikChangeListener from "../ui-kit/formik-change-listener";
import { Select } from "@cargo/ui-kit";
import FormattingButton from "./formatting-button";
import { MenuContext } from "@cargo/common/context-menu";

import InsertContentContextMenu from "../frontend-ui/context-menu-insert-content";

import _ from 'lodash';

class FormattingLayoutTemplateSelect extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectActive: false,
		}

		this.buttonState = 'unavailable';

	}

	componentDidUpdate = (prevProps, prevState) => {
		if( prevProps.pauseGlobalEventExecution === true 
			&& !this.props.pauseGlobalEventExecution
			&& this.state.selectActive
			&& prevState.selectActive ){
			// If we're resuming global event execution and the select menu is active
			// it's safe to assume we've closed the menu and can remove the active state.
			this.setState({ selectActive: false })
		}
	}

	retrieveButtonState = (newButtonState) => {
		this.buttonState = newButtonState;
	}
	
	render() {
		const { className, activeWindow, tooltip, toolTipIcon, data, } = this.props;
		const classList = `${className !== undefined ? className : ''}`;

		return (
			<MenuContext.Consumer>
				{(Menu) => 
					<FormattingButton
						commandName = {this.props.commandName}
						alternateCommand = { this.props.alternateCommand ?? undefined }
						tooltip = {this.props.tooltip}
						data = {this.props.data}
						component={Select}
						retrieveButtonState = {(newButtonState)=>this.retrieveButtonState(newButtonState)}
						className={`button-select${this.state.selectActive ? ' select-active' : ''}`}
						id = "layout-template-select"
						label={
							<>
								<label className="select-display">
									{/* <svg className="plus-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
									{/* 	<path fillRule="evenodd" clipRule="evenodd" d="M17.364 17.364C13.8492 20.8787 8.15076 20.8787 4.63604 17.364C1.12132 13.8492 1.12132 8.15076 4.63604 4.63604C8.15076 1.12132 13.8492 1.12132 17.364 4.63604C20.8787 8.15076 20.8787 13.8492 17.364 17.364ZM3.92893 18.0711C7.83418 21.9763 14.1658 21.9763 18.0711 18.0711C21.9763 14.1658 21.9763 7.83418 18.0711 3.92893C14.1658 0.0236892 7.83418 0.0236892 3.92893 3.92893C0.0236891 7.83418 0.0236894 14.1658 3.92893 18.0711ZM10.2502 16.845L10.2501 11.7502L5.15532 11.7502L5.15535 10.2502H10.2502V5.15535H11.7502L11.7502 10.2502L16.8449 10.2502L16.8449 11.7502L11.7502 11.7502L11.7502 16.8449L10.2502 16.845Z" fill="black"/> */}
									{/* </svg> */}
									<svg className="plus-icon" width="20" height="20" viewBox="0 0 20 20">
										<circle cx="10" cy="10" r="8" />
										<g>
											<line x1="10" y1="5" x2="10" y2="15"></line>
											<line x1="5" y1="10" x2="15" y2="10"></line>
										</g>
									</svg>
									<span>{`\u00A0`}Add...</span>
								</label>
								<div className="select-arrows"><div></div></div>
							</>
						}
						onMouseDown = { e => {
							if (this.buttonState !== 'unavailable') {
								e.preventDefault();

								if( !this.state.selectActive ){
									this.setState({selectActive: true})
								}

								// open the menu
								Menu.openMenu({
									innerUI: <InsertContentContextMenu />,
									type: 'button',
									event: e,
									offset: this.props.data.offset
								}) 

							}
								
						}}
					/>
				}
			</MenuContext.Consumer>
		);

	}

	onEscape(event){
	  if(event.keyCode === 27) {
	  	if( this.state.selectActive ){
	  		this.setState({selectActive: false})
	  	}
	  }
	}

};

FormattingLayoutTemplateSelect.contextType = EditorContext;

function mapReduxStateToProps(state, ownProps) {
	return {
		pauseGlobalEventExecution: state.adminState.pauseGlobalEventExecution,
		PIDBeingEdited: state.frontendState.PIDBeingEdited,
		openUIWindows: _.map(state.uiWindows.byGroup['right-menu-bar'], uiWindowID => state.uiWindows.byId[uiWindowID]),
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateAdminState: actions.updateAdminState,
		addUIWindow: actions.addUIWindow,
		updateUIWindow: actions.updateUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);
}

export default connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(FormattingLayoutTemplateSelect);
