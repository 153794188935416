import React, { Component } from 'react';
import { EditorContext } from "../page-editor";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";

import { Select } from "@cargo/ui-kit";
import FormattingButton from "./formatting-button";

import { MenuContext } from "@cargo/common/context-menu";
import FormattingButtonContextUI from "./formatting-button-context-ui";
import { ToolTip } from "../ui-kit";
import _ from 'lodash';

class FormattingButtonContextMenu extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showTooltip: false,
			selectActive: false,
			buttonPos: {
				x: null,
				y: null,
				width: null,
				height: null
			},
			currentIcon: {
				name: '',
				tag: ''
			}
		}

		this.buttonState = 'unavailable';

	}

	updateCurrentMenuIcon = commandStyle => {
		const {name, tag} = commandStyle;

		this.setState({
			currentIcon: {
				name, 
				tag
			}
		});
	}

	retrieveButtonState = (newButtonState) => {
		this.buttonState = newButtonState;
	}

	render() {

		const { className, activeWindow, tooltip, toolTipIcon, data, } = this.props;
		const classList = `${className !== undefined ? className : ''}`;
		return (
			<>

			<MenuContext.Consumer>
				{(Menu) => 
					<FormattingButton
						commandName = {this.props.commandName}
						alternateCommand= { this.props.alternateCommand ?? undefined }
						tooltip = {this.props.tooltip}
						data = {this.props.data}
						component={Select}
						retrieveButtonState = {(newButtonState)=>this.retrieveButtonState(newButtonState)}
						className="button-select"
						label={this.props.label}
						onMouseDown = { e => {
							if (this.buttonState !== 'unavailable') {
								e.preventDefault();
								// open the menu
								Menu.openMenu({
									innerUI: <>{
										this.props.data.childUI ? 
											this.props.data.childUI 
										: 
											<FormattingButtonContextUI 
												options={this.props.data.options}
												header={this.props.data.header !== false ? this.props.tooltip : false}
											/>
									}</>,
									type: 'button',
									event: e,
									offset: this.props.data.offset ? this.props.data.offset : null
								}) 

							}
								
						}}
					/>
				}
			</MenuContext.Consumer>
			</>
		);

	}

	hasOptionApplied() {

	}

	onEscape(event){
	  if(event.keyCode === 27) {
	  	if( this.state.selectActive ){
	  		this.setState({selectActive: false})
	  	}
	  }
	}

};

FormattingButtonContextMenu.contextType = EditorContext;

function mapReduxStateToProps(state, ownProps) {
	return {
		adminState: state.adminState,
		PIDBeingEdited: state.frontendState.PIDBeingEdited,
		openUIWindows: _.map(state.uiWindows.byGroup['right-menu-bar'], uiWindowID => state.uiWindows.byId[uiWindowID]),
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateAdminState: actions.updateAdminState,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);
}

export default connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(FormattingButtonContextMenu);
