import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { commands } from "../../lib/inline-editor";
import { MoreButton } from "../ui-kit";
import { Alert, AlertContext, Button } from "@cargo/ui-kit";
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";
import { deriveFontDisplayName, deriveVariantFromCSS, createFVSPropertyMap } from "@cargo/common/font-helpers.js";
import { withPropWatchers } from "../../lib/shared-css";

class FormattingTextStylesContextUIComponent extends Component {

	constructor(props) { 
		super(props);

		this.state = {};
		
		this.dynamicTypeStylesData = this.props.subscribedSelectors;

	};

	componentDidMount(){
	}

	shouldComponentUpdate(nextProps, nextState) {

		let isEqual = _.isEqual(nextProps, this.props);

		if( !isEqual ){
			return true
		}

		return false

	}

	getPreviewName = (selectorValues) => {
		if (this.props.fontCollectionLoaded) {
			return deriveFontDisplayName(this.props.fontCollection, this.getFontSettings(selectorValues), 'select') ?? selectorValues['font-family'];

		}
		return null
	}

	getFontSettings = (selectorValues) => {

		let collection = this.props.fontCollection?.collection;
		let familyInCSS = selectorValues['font-family'];
		let family     = _.first(selectorValues['font-family']?.split(','))?.trim()?.replace(/^"|"$/g, '');
		let style      = selectorValues['font-style'];
		let weight     = selectorValues['font-weight']
		let fvs        = selectorValues['font-variation-settings']

		if( fvs ){
			const fvsMap = createFVSPropertyMap( fvs );
			if( fvsMap['wght'] ){
				weight = fvsMap['wght'];
			}
		}

		if( !family ){
			console.log("Could not find family in prop watcher values", this.props.selector, this.props.propWatcherValues);
		};
		
		let variant = deriveVariantFromCSS( this.props.fontCollection?.collection, family, style, weight, fvs, selectorValues['font-family'], familyInCSS );

		return {
			family,
			variant,
			style,
			weight,
			fvs,
			familyInCSS
		}

	}

	render() {
		const DynamicTypeStyles = () => {
			return (
				this.dynamicTypeStylesData.map(typeStyle => {

					const fontFamilyName = this.getPreviewName(this.props.propWatcherValues[typeStyle.tag])

					return (
						<ContextMenuCheckbox
							key={typeStyle.name}
							label={typeStyle.name}
							icon={<span className="type-hint font">{fontFamilyName}</span>}
							name={typeStyle.tag}
							command={typeStyle.tag}
							value={ typeStyle.tag === this.props.currentTypeStyle.tag ? true : false }
							onPointerUp = { e => {
								// prevent default so focus is not lost in the editor
								e.preventDefault();
								this.props.changeTypeStyle(`${typeStyle.tag}`);
							}}
						/>
					)
				}
			)
		)};

		const NonDynamicTypeStyles = () => {
			return (
				this.props.textStyleDefaults.map(typeStyle => {

					const fontFamilyName = this.getPreviewName(this.props.propWatcherValues[typeStyle.tag])

					return (
						<React.Fragment key={typeStyle.name}>
							{typeStyle.name === 'space' ?
								<hr />
							:
							// Default Inputs
								<ContextMenuCheckbox
									label={typeStyle.name}
									icon={<span className="type-hint font">{fontFamilyName}</span>}
									name={typeStyle.tag}
									command={typeStyle.tag}
									disabled={this.props.currentTypeStyle.tag === "bodycopy" && typeStyle.tag === "bodycopy" ? true : false}
									value={ typeStyle.tag === this.props.currentTypeStyle.tag ? true : false }
									onPointerUp = { e => {
										this.props.changeTypeStyle(`${typeStyle.tag}`);
									}}
								/>

							}
						</React.Fragment>
					)
				}
			)
		)};

		return (
			<>

				<ContextMenuButton
					label="Text Style"
					disabled
				/>

				<NonDynamicTypeStyles />

				{this.dynamicTypeStylesData.length > 0 ?
					<>
						<DynamicTypeStyles/>
					</>
				: null }

				<hr/>

				<ContextMenuButton 
					label="Edit Styles..." 
					onPointerUp = { e => { 
						this.props.toggleUIWindow(null, {
							group: 'right-menu-bar',
							component: import('../right-menu-bar/text-styles-window'),
							windowId: 'text-styles-window',
							uiWindowType: 'popover',
							positionType: 'fixed-right',
							borderRadius: 'radius-all',
							windowName: 'text-styles',
							removeGroupName: 'main',
							selectedTextStyle: this.props.currentTypeStyle
						})
					}}
				/>

				<ContextMenuButton 
					label="New Style..." 
					onPointerUp = { e => { 
						this.props.toggleUIWindow(null, {
							group: 'right-menu-bar',
							component: import('../right-menu-bar/text-styles-window'),
							windowId: 'text-styles-window',
							uiWindowType: 'popover',
							positionType: 'fixed-right',
							borderRadius: 'radius-all',
							windowName: 'text-styles',
							removeGroupName: 'main',
							showNewTextStyleFlow: true
						})
					}}
				/>	
				
			</>

		)
	};

};

const memoizedWatchedProperties = createSelector(
	(ownProps) => ownProps.textStyleDefaults,
	(ownProps) => ownProps.subscribedSelectors,
	(
		textStyleDefaults,
		subscribedSelectors
	) => {

		const config = {};
		const allSelectors = [...textStyleDefaults, ...subscribedSelectors];

		allSelectors.forEach(selector => {

			config[selector.tag] = [
				'font-size',
				'font-variant-name',
				'font-family',
				'font-weight',
				'font-style',
				'font-variation-settings',
				'font-feature-settings'
			]

		})


		return config;

	}
);

const mapReduxStateToProps = (state, ownProps) => {

	return {
		fontCollection: state.fontCollection,
		fontCollectionLoaded: _.get(state.fontCollection, 'hasFontCollection'),
	};

}

export const FormattingTextStylesContextUI = connect(
	mapReduxStateToProps
	)(withPropWatchers(FormattingTextStylesContextUIComponent,  {
		parser: 'global',
		watchedProperties: memoizedWatchedProperties
	})
);


