import React from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import { actions } from "../../actions";
import { Alert, AlertContext, Button } from "@cargo/ui-kit";
import { HotKeyProxy } from '../ui-kit';
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";
import { setHomePage, pinContent, deleteContent, duplicateContent } from "../../lib/page-list-manager";

const TitleBarContextUI = (props) => {

    const isHomepage = props.page.id === props.site.homepage_id;

	return (
		<>
			<ContextMenuCheckbox
				label="Home"
				name="is_homepage"
				value={ isHomepage }
				icon={
					<svg x="0px" y="0px" viewBox="0 0 24.4 20">
						<polygon points="12.2,0 0,12.1 4.3,12.1 4.3,20 10.4,20 10.4,13 14,13 14,20 20.1,20 20.1,12.1 24.4,12.1 "/>
					</svg>
				}
				onPointerUp = { e => { 
					setHomePage(props.page.id, !isHomepage);
				}}
			/>

			<ContextMenuCheckbox
				label="Pin..."
				name="pin"
				value={ props.page.pin }
				icon={
					<svg x="0px" y="0px" viewBox="0 0 20 20">
					<path d="M19.8,5.7L19.8,5.7l-5.5-5.5C14.1,0,13.8,0,13.5,0c-0.3,0.1-0.5,0.2-0.6,0.5c-0.3,0.8-0.4,1.8-0.2,2.7L7.4,7.9
						c-2.9-1-5.2,0.6-5.3,0.6C1.9,8.7,1.8,8.9,1.8,9.1c0,0.2,0.1,0.5,0.2,0.6l3.8,3.8l-5.6,5.6C0,19.3,0,19.4,0,19.5c0,0.1,0,0.2,0.1,0.3
						C0.2,20,0.4,20,0.5,20c0.1,0,0.2,0,0.3-0.1l5.6-5.6l3.8,3.8c0.1,0.1,0.3,0.2,0.6,0.2c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.6-0.3
						c0.1-0.1,0.5-0.7,0.8-1.7c0.3-1.2,0.3-2.4-0.1-3.6l4.7-5.4c0.9,0.2,1.9,0.1,2.7-0.2c0.3-0.1,0.4-0.3,0.5-0.6C20,6.2,20,5.9,19.8,5.7
						z"/>
					</svg>
				}
				onPointerUp = { e => {

					pinContent(props.page.id, !props.page.pin);

					if (props.page.pin === false) {

						props.addUIWindow({
							group: 'modal',
							component: import('../right-menu-bar/pin-settings-window'),
							id: 'pin-settings-window',
							props: {
								type: 'popover', 
								positionType: 'fixed-right', 
								borderRadius: 'radius-all', 
								autoHeight: true,
								pageID: props.page.id,
								windowName: 'pin-settings',
								clickoutLayer: true,
								additionalMarginBottom: 10
							}
						},{
							removeGroup: true
						});

					}
				}}
			/>

			{ props.page.pin ? (
				<ContextMenuButton 
					label={`${'\u00A0\u00A0\u00A0\u00A0'}Pin Settings...`}
					onPointerUp = { e => { 
						props.addUIWindow({
							group: 'modal',
							component: import('../right-menu-bar/pin-settings-window'),
							id: 'pin-settings-window',
							props: {
								type: 'popover', 
								positionType: 'fixed-right', 
								borderRadius: 'radius-all', 
								autoHeight: true,
								windowName: 'pin-settings',
								// clickoutLayer: true
								closeOnSingleClickout: true
							}
						},{
							removeGroup: true
						})
					}}
				/>	
			) : ( null )}

			<hr/>

			<ContextMenuButton 
				label="Duplicate" 
				onPointerUp = { e => { 
					duplicateContent('page', props.page.id, {callback: (duplicatedPageModel) => {
						props.history.push('/'+duplicatedPageModel.id)
					}})
				}}
			/>	
			{/* <ContextMenuButton  */}
			{/* 	label="Delete..."  */}
			{/* 	onPointerUp = { e => { */}
			{/* 		deleteContent('page', props.page.id) */}
			{/* 	}} */}
			{/* /> */}
			<AlertContext.Consumer>
			    {(Alert) => (
			    	<ContextMenuButton 
			    		label="Delete" 
			    		onPointerUp = { e => {
		            		Alert.openModal({
		            		    header: 'Delete page “'+props.page.title+'”?',
		            		    // message: 'Deleted pages cannot be recovered', 
		            		    type: 'confirm',
		            		    denyButton: 'Cancel',
		            		    confirmButton: 'OK',
		            		    HotKeyProxy: HotKeyProxy,
		            		    onConfirm: (options) => {
		            		    	deleteContent('page', props.page.id)
		            		    },
		            		    onDeny: (options) => {},
		            		    onClose: () => {}
	            		   });
			    			
			    		}}
			    	/>
			    )}
			</AlertContext.Consumer>

			<hr/>

			<ContextMenuButton 
				label={<>Direct Link <em>↗</em></>}
				onPointerUp = { e => {
					let pageLink = props.site.direct_link+"/"+props.page.purl;
					window.open(pageLink, "_blank")
				}}
			/>

		</>
	);

};

function mapReduxStateToProps(state, ownProps) {

	return {
		adminState : state.adminState,
	};

}


function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);

}

export default withRouter( connect(
	mapReduxStateToProps,
	mapDispatchToProps
)( TitleBarContextUI ));
