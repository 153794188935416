import React, { Component } from 'react';
import FormattingMenu from "./top-menu-bar/formatting-menu";
import DeviceMenu from "./top-menu-bar/device-menu";
import Navigation from "./top-menu-bar/navigation";
import { Button } from '@cargo/ui-kit';
import { connect } from 'react-redux';
import { HOMEPAGE_ORIGIN } from "@cargo/common";

class TopMenuBar extends Component {
	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div className="top-menu-bar">
				<FormattingMenu />
				<DeviceMenu />
				<Navigation />
			</div>
		);

	}

}


function mapReduxStateToProps(state) {

	return {
		email: state.user?.email
	};

}

export default connect(
	mapReduxStateToProps
)(TopMenuBar);