import React, { Component, useContext } from 'react';
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox, ContextMenu, MenuContext } from "@cargo/common/context-menu"
import FigureEditorContextMenu from "./context-menu-figure-editor"
import InsertContentContextMenu from "./context-menu-insert-content"
import DigitalClockContextMenu from "./context-menu-digital-clock"

// this is a component that allows components in the Frontend to open context menus
class ContextMenuOpener extends Component {
	constructor(props){
		super(props);
	}

	render(){
		return null;
	}

	openContextMenu = (e, menuName, options) =>{

		console.log(e, menuName, options);

		const clientFrame = document.querySelector('#client-frame');
		let clientFrameRect = clientFrame ? clientFrame.getBoundingClientRect() : {
			top: 0,
			left: 0
		}

		const {
			element,
			elementRect
		} = options

		// keep the menu name & element around for when the context menu gets opened in the onMouseDown callback
		// here we convert the native browser event, passed via preact in the frontend window into a new one
		const newEvent = new PointerEvent(e.type, {
			bubbles: false,
			clientY: e.clientY + clientFrameRect.top,
			offsetY: e.offsetY + clientFrameRect.top,
			layerY: e.layerY + clientFrameRect.top,
			y: e.y + clientFrameRect.top,
			clientX: e.clientX + clientFrameRect.left,
			offsetX: e.offsetX + clientFrameRect.left,
			layerX: e.layerX + clientFrameRect.left,
			x: e.x + clientFrameRect.left
		});

		const newRect = elementRect ? {
			x: elementRect.x + clientFrameRect.left,
			y: elementRect.y + clientFrameRect.top,
			top: elementRect.top + clientFrameRect.top,
			right: elementRect.right,
			bottom: elementRect.bottom,
			left: elementRect.left + clientFrameRect.left,
			height: elementRect.height,
			width: elementRect.width,
		} : {x: 0, y: clientFrameRect.top, top: clientFrameRect.top, left: 0, bottom: clientFrameRect.top, right: 0, width: 0, height: 0}

		const newOptions = {
			...options,
			elementRect:newRect
		}

		switch (menuName){
			case "digital-clock":
				this.context.openMenu({
					innerUI: <DigitalClockContextMenu {...newOptions} />,
					type: 'mouse',
					event: newEvent
				});
			break;			
			case "figure-editor":
				this.context.openMenu({
					innerUI: <FigureEditorContextMenu {...newOptions} />,
					type: 'mouse',
					event: newEvent
				});
			break;
			case "insert-content":
				this.context.openMenu({
					innerUI: <InsertContentContextMenu {...newOptions} />,
					type: 'button',
					event: e,
					offset: {x: -7, y: 32}
					// type: 'mouse',
					// event: newEvent
				});
			break;
		}

	}

	componentDidMount(){
		window.ContextMenuOpener = this;
	}

}

ContextMenuOpener.contextType = MenuContext;

export default ContextMenuOpener