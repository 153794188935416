import React, { Component, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import { withRouter} from 'react-router-dom';
import { getDefaultEditingPID } from '../../lib/page-list-manager'
import _ from 'lodash';

import { Formik, Field } from 'formik';
import FormikChangeListener from "../ui-kit/formik-change-listener";
import { RadioButton, HotKey } from "../ui-kit";

import DesktopIcon from "@cargo/common/icons/device-menu/desktop.svg";
import MobileIcon from "@cargo/common/icons/device-menu/mobile.svg";

const DeviceMenu = props => {

	useEffect(() => {
	    if (props.viewport === 'mobile') {
	    	// Open the Mobile Design Styles popover if we're swapping to mobile viewport.
	    	openMobileSettingsUIWindow();
	    }
	    if ( props.viewport !== 'mobile' ){
	    	props.removeUIWindow(uiWindow => {
	    		return uiWindow.id === 'mobile-window';
	    	});
	    }
	}, [props.viewport]);

	const openMobileSettingsUIWindow = () => {

		if( !props.uiWindows.byId['mobile-window'] ){
			// Manually add design styles window 
			props.addUIWindow({
				group: 'mobile',
				component: import('../right-menu-bar/mobile-settings-ui'),
				id: 'mobile-window',
				props: {
					type            : 'popover', 
					positionType    : 'fixed-left', 
					tabbed			: true,
					windowName 		: 'mobile-window',
					supportsMobile 	: true,
					fullHeight 		: false,
					preventEsc      : true,
					ignoreClickout 	: true,
					requireDiscreteClose: true
				}
			},{
    			removeAll: false
    		});

			// remove all windows but the page list, mobile window, and help window.
    		// props.removeUIWindow(uiWindow => {
			// 	return uiWindow.id !== 'page-list-window' && uiWindow.id !== 'mobile-window' && uiWindow.id !== 'page-settings-window' && uiWindow.id !== 'pin-settings-window' && uiWindow.id !== 'help-window';
			// });

		}

	}

	const toggleBetweenViewports = () => {

		let viewport = props.viewport === 'mobile' ? 'desktop' : 'mobile';
		onViewPortChange({'viewport': viewport});
	}

	const onViewPortChange = (changes) => {

		props.updateAdminState(changes);

		const renderedPageIds = props.renderedPages.map(el => el.id);

		if( Object.keys(changes)[0] === 'viewport' && changes?.viewport === 'mobile' ){

			// switching to a mobile viewport
			if(
				// if desktop homepage is rendered
				renderedPageIds.includes(props.desktopHomepageId)
				// and we also have a mobile homepage
				&& props.mobileHomepageId
				// and the mobile homepage is not the same as the desktop homepage
				&& props.mobileHomepageId !== props.desktopHomepageId
			) {

				// start editing the mobile homepage instead of the desktop version
				getDefaultEditingPID().then(pid => {
					props.history.replace('/' + pid);
				})

			}

			

		} else {

			// switching to desktop viewport
			if(
				// if mobile homepage is rendered
				renderedPageIds.includes(props.mobileHomepageId)
				// and we also have a desktop homepage
				&& props.desktopHomepageId
				// and the desktop homepage is not the same as the mobile homepage
				&& props.desktopHomepageId !== props.mobileHomepageId
			) {
			
				// start editing the desktop homepage instead of the mobile version
				getDefaultEditingPID().then(pid => {
					props.history.replace('/' + pid);
				});

			}
			
			props.removeUIWindow(uiWindow => {
				return uiWindow.group === 'mobile'
			});

		}

	}

	return (
		<div id="device-menu">

			<HotKey shortcut="cmd+shift+x" key={props.viewport} boundTo="all" callback={() => {
				toggleBetweenViewports();
			}} />

			<Formik
				// this is important for websocket updating
				enableReinitialize
				// defaults are set here
				initialValues={{
					viewport: props.viewport
				}}
			>
			{props => (

				<>	

					{/* our own change listener implementation to notify us of any changes to the fields */}
					<FormikChangeListener onChange={onViewPortChange}/>
					{/* Formik fields */}
					<div className="radio-button-group">
						<Field 
							component={RadioButton} 
							name="viewport" 
							value="desktop" 
							tooltip="Desktop<em>⇧⌘X</em>"
							tooltipOffsetX={ 5 }
							tooltipOffsetY ={ -1 }
							label={<DesktopIcon />}
						/>
						<Field 
							component={RadioButton} 
							name="viewport" 
							value="mobile" 
							tooltip="Mobile Preview<em>⇧⌘X</em>"
							tooltipOffsetX={ 4 }
							tooltipOffsetY ={ 18 }
							className="mobile-toggle"
							label={<MobileIcon />}
						/>
					</div>
				</>
			)}
			</Formik>

		</div>
	);

}

function mapReduxStateToProps(state, ownProps) {

	return {
		viewport: state.adminState.viewport,
		mobileHomepageId: state.site.mobile_homepage_id,
		desktopHomepageId: state.site.homepage_id,
		renderedPages: state.frontendState.renderedPages,
		uiWindows: state.uiWindows
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		updateAdminState: actions.updateAdminState,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);

}

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(DeviceMenu))
