import React, { Component } from 'react';
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox, ContextMenu, MenuContext } from "@cargo/common/context-menu"
import { FRONTEND_DATA } from "../../globals";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";


import _ from 'lodash';

const timeValues = {
	'day': 'Day',
	'date': 'Date',
	'ordinal': 'Ordinal Date',
	'month': 'Month',
	'month-name': 'Month Name',
	'period': 'AM / PM',
	'year': 'Year',
	'hour': 'Hour',
	'minute': 'Minute',
	'second': 'Second',
}


const ordinals = [
	"first",
	"second",
	"third",
	"fourth",
	"fifth",
	"sixth",
	"seventh",
	"eighth",
	"ninth",
	"tenth",
	"eleventh",
	"twelfth",
	"thirteenth",
	"fourteenth",
	"fifteenth",
	"sixteenth",
	"seventeenth",
	"eighteenth",
	"nineteenth",
	"twentieth",
	"twenty-first",
	"twenty-second",
	"twenty-third",
	"twenty-fourth",
	"twenty-fifth",
	"twenty-sixth",
	"twenty-seventh",
	"twenty-eighth",
	"twenty-ninth",
	"thirtieth",
	"thirty-first",
]

const days = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

const periods = [
	'AM',
	'PM',
]

/** digital clock defaults:
 {
	'pad': false,
	'pad-date': false,
	'pad-hour': false,
	'pad-minute': true,
	'pad-second': true,
	'timezone': 'local',
	'value': 'second',
}
**/

class DigitalClockContextMenu extends Component {

	constructor(props){
		super(props);

		this.state = {
		}
	}
	pad =(number) => {
		if( number < 10){
			return '0'+ number;
		} else {
			return number.toString();
		}
		
	}

	updateTime =()=>{

		let time = new Date();

		const timezone = this.props.clickedElement.getAttribute('timezone') || 'local';

		if( timezone !== 'local'){
			const localOffset = time.getTimezoneOffset();	

			let parsedTimezone = timezone

			if( timezone.indexOf(':') > 1 ){
				const timezoneArray = timezone.split(':');
				// just discard anything more finegrained than minutes...
				timezoneArray.length = 2;

				parsedTimezone = parseFloat(timezoneArray[0])+parseFloat(timezoneArray[1] || 0)/60;
			} 

			const offset = ( -(-localOffset -parseFloat(parsedTimezone*60)) || 0) ;
			const timestamp = Date.now();

			time = new Date(timestamp+offset*60000)
		}
		
		const
			date = time.getDate(),

			day = days[time.getDay()],

			month = time.getMonth(),
			year = time.getFullYear(),

			hours = time.getHours(),
			minute = time.getMinutes(),
			second = time.getSeconds();


		this.setState({
			day,
			padDate: this.pad(date),			
			date,

			['ordinal']: ordinals[date-1],
			['month-name']: months[month],
			month: month+1,
			padMonth: this.pad(month+1),

			year,
			truncateYear: year.toString().substring(2,4) ,

			period: hours >= 12 ? periods[1]: periods[0],

			pad12hour: this.pad((hours== 0 ? 12: hours%12)),
			['12hour']: (hours== 0 ? 12: hours%12),

			pad24hour: this.pad(hours),
			['24hour']: hours,

			padMinute: this.pad(minute),
			minute,
			padSecond: this.pad(second),
			second,
		}, ()=>{
			this.updateTimeAnimationFrame = requestAnimationFrame(this.updateTime);
		});
	}

	render(){

		const {
			clickedElement,
			elements,
		} = this.props;


		const value = clickedElement.getAttribute('value') || 'second';
		let valueIsValidSingle = Object.keys(timeValues).includes(value);

		const enablePad = !(value =='month-name' || value == 'ordinal' || value == 'day' || value=='year' || value=='period');

		const padIsActive = (
			clickedElement.getAttribute('pad') === 'true' ||
			(value === 'date' && clickedElement.getAttribute('pad-date') == 'true') ||			
			(value === 'hour' && clickedElement.getAttribute('pad-hour') == 'true') ||
			(value === 'month' && clickedElement.getAttribute('pad-month') == 'true') ||			
			(value === 'second' && (clickedElement.getAttribute('pad-second') == 'true' || !clickedElement.hasAttribute('pad-second') )) ||
			(value === 'minute' && (clickedElement.getAttribute('pad-minute') == 'true' || !clickedElement.hasAttribute('pad-minute') ))
		)

		const truncated = clickedElement.getAttribute('truncate-year') === 'true';
		const twentyFourHour = clickedElement.getAttribute('twentyfour-hour') === 'true';
		const enableTwentyFour = value ==='hour' || value.includes('{hour}');


		const enableTruncate = value==='year' || value.includes('{year}');

		const timezone = clickedElement.getAttribute('timezone') || 'local';
		const timezones = [];
		let timezoneFound = false;
		for(let i = -12; i <= 14; i++){
			const offsetLabel = i >=0 ?  '+'+i+':00' : +i+':00';
			timezoneFound = timezoneFound || (timezone== i || timezone == offsetLabel);			
			timezones.push(<ContextMenuCheckbox
				key={`${offsetLabel} UTC`}
				label={`${offsetLabel} UTC`}
				onPointerUp={ (e) => {
					this.chooseTimeZone(e, i);
				}}
				value={timezone== i || timezone == offsetLabel}
			/>)
		}
		timezones.push(<ContextMenuCheckbox
			key={`Local`}
			label={`Local`}
			onPointerUp={ (e) => {
				this.chooseTimeZone(e, 'local');
			}}
			value={!timezoneFound}
		/>);


		return <>
			<ContextSubMenu
				layer={1}
				label={`${valueIsValidSingle ? 'Display: '+timeValues[value] : 'Choose Time Format:'}`}
			>
				<ContextMenuCheckbox
					label={`${this.state.day}, ${this.state['month-name']} ${this.state.date}, ${this.state.year} ${this.state['12hour']}:${this.state.padMinute} ${this.state.period}`}
					onPointerUp={ (e) => {
						this.choosePreset(e,{
							value: '{day}, {month-name} {date}, {year} {hour}:{minute} {period}',
							'truncate-year': false,
							'pad-date': false,
							'pad-hour': false,
							'pad-minute': true,
							'twentyfour-hour': false,
						})
					}}
					value={value=='{day}, {month-name} {date}, {year} {hour}:{minute} {period}'}
				/>			
				<ContextMenuCheckbox
					label={`${this.state.pad12hour}:${this.state.padMinute}:${this.state.padSecond} ${this.state.period}`}
					onPointerUp={ (e) => {
						this.choosePreset(e,{
							value: '{hour}:{minute}:{second} {period}',
							'twentyfour-hour': false,
							'pad-hour': true,
							'pad-minute': true,
							'pad-second': true,
						})
					}}
					value={value=='{hour}:{minute}:{second} {period}'}
				/>
				<ContextMenuCheckbox
					label={`${this.state['pad24hour']}:${this.state.padMinute}:${this.state.padSecond}`}
					onPointerUp={ (e) => {
						this.choosePreset(e,{
							value: '{hour}:{minute}:{second}',
							'twentyfour-hour': true,
							'pad-hour': true,
							'pad-minute': true,
							'pad-second': true,
						})
					}}
					value={value=='{hour}:{minute}:{second}'}
				/>	
				<ContextMenuCheckbox
					label={`${this.state.padDate}-${this.state.padMonth}-${this.state.year}`}
					onPointerUp={ (e) => {
						this.choosePreset(e,{
							value: '{date}-{month}-{year}',
							'truncate-year': false,
							'pad-month': true,
							'pad-date': true,
						})
					}}
					value={value=='{date}-{month}-{year}'}
				/>
				<ContextMenuCheckbox
					label={`${this.state.date} ${this.state['month-name']} ${this.state.year}`}
					onPointerUp={ (e) => {
						this.choosePreset(e,{
							value: '{date} {month-name} {year}',
							'truncate-year': false,
							'pad-date': false,
						})
					}}
					value={value=='{date} {month-name} {year}'}
				/>
				<ContextMenuCheckbox
					label={`${this.state['month-name']} ${this.state.date}, ${this.state.year}`}
					onPointerUp={ (e) => {
						this.choosePreset(e,{
							value: '{month-name} {date}, {year}',
							'truncate-year': false,
							'pad-date': false,
						})
					}}
					value={value=='{month-name} {date}, {year}'}
				/>
				<ContextMenuCheckbox
					label={`${this.state['month']}/${this.state.date}/${this.state.year}`}
					onPointerUp={ (e) => {
						this.choosePreset(e,{
							value: '{month}/{date}/{year}',
							'truncate-year': true,
							'pad-month': true,
							'pad-date': true,
						})
					}}
					value={value=='{month}/{date}/{year}'}
				/>
				<ContextMenuCheckbox
					label={`${this.state.date}/${this.state.month}/${this.state.year}`}
					onPointerUp={ (e) => {
						this.choosePreset(e,{
							value: '{date}/{month}/{year}',
							'truncate-year': true,
							'pad-month': true,
							'pad-date': true,
						})
					}}
					value={value=='{date}/{month/{year}'}
				/>
				{/* <ContextSubMenu */}
				{/* 	layer={2} */}
				{/* 	label={`${valueIsValidSingle ? 'Single Value: '+timeValues[value] : 'Choose Single Value:'}`} */}
				{/* > */}
					<hr/>
					<ContextMenuCheckbox
						label={"Seconds"}
						onPointerUp={ (e) => {
							this.chooseValue(e,'second')
						}}
						value={value=='second'}
					/>
					<ContextMenuCheckbox
						label={"Minutes"}
						onPointerUp={ (e) => {
							this.chooseValue(e,'minute')
						}}
						value={value=='minute'}
					/>
					<ContextMenuCheckbox
						label={"Hours"}
						onPointerUp={ (e) => {
							this.chooseValue(e,'hour')
						}}
						value={value=='hour'}
					/>
					<ContextMenuCheckbox
						label={"AM/PM"}
						onPointerUp={ (e) => {
							this.chooseValue(e,'period')
						}}
						value={value=='period'}
					/>					
					<ContextMenuCheckbox
						label={"Day"}
						onPointerUp={ (e) => {
							this.chooseValue(e,'day')
						}}
						value={value=='day'}
					/>					
					<ContextMenuCheckbox
						label={"Date"}
						onPointerUp={ (e) => {
							this.chooseValue(e,'date')
						}}
						value={value=='date'}
					/>
					<ContextMenuCheckbox
						label={"Ordinal Date"}
						onPointerUp={ (e) => {
							this.chooseValue(e,'ordinal')
						}}
						value={value=='ordinal'}
					/>				
					<ContextMenuCheckbox
						label={"Month"}
						onPointerUp={ (e) => {
							this.chooseValue(e,'month')
						}}
						value={value=='month'}
					/>
					<ContextMenuCheckbox
						label={"Month Name"}
						onPointerUp={ (e) => {
							this.chooseValue(e,'month-name')
						}}
						value={value=='month-name'}
					/>						
					<ContextMenuCheckbox
						label={"Year"}
						onPointerUp={ (e) => {
							this.chooseValue(e,'year')
						}}
						value={value=='year'}
					/>
				{/* </ContextSubMenu>			 */}
				
			</ContextSubMenu>
			<ContextSubMenu
				layer={1}
				label={`Time Zone: ${ isNaN(parseFloat(timezone)) ? 'Local' : timezone >= 0 ? '+'+timezone+' UTC' : timezone < 0 ? timezone+" UTC" : ''}`}
			>
				{timezones}
			</ContextSubMenu>
			{enableTwentyFour ? <ContextMenuCheckbox

				label={`24-Hour Clock`}
				
				value={ twentyFourHour }
				onPointerUp = { e => {
					// prevent default so focus is not lost in the editor
					e.preventDefault();
					

					FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{

						elements.forEach(el=>{
							el.setAttribute('twentyfour-hour', !twentyFourHour);
						});						
						
					});						

				}}
			/>: null}
			{enableTruncate ? <ContextMenuCheckbox

				label={`Truncate Year`}
				
				value={ truncated }
				onPointerUp = { e => {
					// prevent default so focus is not lost in the editor
					e.preventDefault();
					

					FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{

						elements.forEach(el=>{
							el.setAttribute('truncate-year', !truncated);
						});						
						
					});						

				}}
			/>: null}
			{enablePad ? <ContextMenuCheckbox

				label={`Pad ${ timeValues[value] || 'Time' } Display`}
				
				value={ padIsActive }
				onPointerUp = { e => {
					// prevent default so focus is not lost in the editor
					e.preventDefault();
					

					FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{

						if( valueIsValidSingle ){

							if( padIsActive ){
								elements.forEach(el=>{
									el.removeAttribute('pad');
									el.setAttribute('pad-'+value, false);
								});
							} else {
								elements.forEach(el=>{
									el.removeAttribute('pad');
									el.setAttribute('pad-'+value, true);
								});							
							}

						} else {

							if( padIsActive ){
								elements.forEach(el=>{
									el.removeAttribute('pad-hour');
									el.removeAttribute('pad-minute');
									el.removeAttribute('pad-second');
									el.setAttribute('pad', false);
								});
							} else {
								elements.forEach(el=>{
									el.removeAttribute('pad-hour');
									el.removeAttribute('pad-minute');
									el.removeAttribute('pad-second');
									el.setAttribute('pad', true);
								});							
							}
						}
						
					});						

				}}
			/>: null}
			

		</>
	}

	chooseTimeZone = (e, value)=>{
		e.preventDefault();
		FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{

			this.props.elements.forEach(el=>{
				el.setAttribute('timezone', value);
			})

		});		
	}

	choosePreset = (e, attributes)=>{
		e.preventDefault();

		FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{

			this.props.elements.forEach(el=>{

				if( el === this.props.clickedElement){
					Object.keys(attributes).forEach(key=>{
						const val = attributes[key];
						el.setAttribute(key, val);
					});
				} else {
					el.remove();
				}
				
			})

		});

	}

	chooseValue =(e, value) =>{
		e.preventDefault();

		FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{

			this.props.elements.forEach(el=>{
				el.setAttribute('value', value);
			})

		});
	}

	componentDidMount(){
		this.updateTimeAnimationFrame = this.updateTime();
	}

	componentWillUnmount(){
		cancelAnimationFrame(this.updateTimeAnimationFrame);
	}



}


function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);
}

export default connect(
	null, 
	mapDispatchToProps
)(DigitalClockContextMenu);
