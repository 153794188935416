import ColumnsIcon from "../../svg-icons/formatting-columns.svg";
import ImageGalleryIcon from "../../svg-icons/formatting-image-gallery.svg";
import LinkIcon from "../../svg-icons/formatting-link.svg";
import IconsIcon from "../../svg-icons/formatting-iconpalette.svg";
import AlignLeftIcon from "../../svg-icons/formatting-align-left.svg";
import AlignCenterIcon from "../../svg-icons/formatting-align-center.svg";
import AlignRightIcon from "../../svg-icons/formatting-align-right.svg";
import TextOptionsIcon from "../../svg-icons/formatting-textoptions.svg";
import CommerceIcon from "../../svg-icons/formatting-commerce.svg";
import MarqueeIcon from "../../svg-icons/formatting-marquee.svg";
import RotateIcon from "../../svg-icons/formatting-rotate.svg";
import HRIcon from "../../svg-icons/formatting-hr.svg";
import AudioIcon from "../../svg-icons/formatting-audio.svg";
import ListsIcon from "../../svg-icons/formatting-lists.svg";
import OrderedListIcon from "../../svg-icons/formatting-ordered-list.svg";
import MediaOptionsIcon from "../../svg-icons/formatting-media-options.svg";
import ThumbnailIndexIcon from "../../svg-icons/formatting-thumbnail-index.svg";

import CommerceOptionsContextUI from './commerce-button-context-ui';

export const FormattingButtons = [
	{
		name    : 'insertwindow',
		label   : <RotateIcon />,
		window  : true,
		tooltip : 'Insert Window',
		positionType: 'fixed-left',
		borderRadius: 'radius-all',
		size    : 1,
		closeIfUnavailable: false,
		type 	: 'hidden',
	},
	{
		name    : 'commerce',
		type 	: 'button-context-menu',
		childUI : <CommerceOptionsContextUI/>,
		label   : <CommerceIcon />,
		window  : false,
		tooltip : 'Product',
		positionType: 'fixed-left',
		borderRadius: 'radius-all',
		size    : 1,
		closeIfUnavailable: false,
	},

	{
		name    : 'commerce-ineditor',
		type 	: 'hidden',
		window  : true,
		positionType: 'center-under-button',
		borderRadius: 'radius-all',
		size    : 1,
		closeOnAllClickout: true,
		closeIfUnavailable: false,
	},

	{
		name    : 'audioplayer',
		type 	: 'hidden',
		label   : <AudioIcon />,
		window  : false,
		tooltip : 'Audio',
		positionType: 'fixed-left',
		borderRadius: 'radius-all',
		size    : 1,
		closeIfUnavailable: false,
	},
	{
		name    : 'iconpalette',
		label   : <IconsIcon />,
		window  : true,
		tooltip : 'Icons',
		positionType : 'center-under-button',
		borderRadius: 'radius-all',
		maxHeight: 70,
		size    : 1,
		closeIfUnavailable: false,
	},
	{
		name    : 'marquee',
		label   : <MarqueeIcon />,
		window  : true,
		tooltip : 'Marquee',
		positionType: 'fixed-left',
		borderRadius: 'radius-all',
		size    : 1,
		closeIfUnavailable: false,
	},
	
	{
		name    : 'textformatting',
		label   : <ListsIcon />,
		window  : false,
		tooltip : 'List',
		type 	: 'button-context-menu',
		options : [
			{command: 'unordered-list', exec:'unorderedlist', label:'Bulleted List'},
			{command: 'ordered-list',   exec:'orderedlist',   label:'Numbered List'},
			// {command: 'hr', type: 'hr'},
			// {command: 'sup', 			exec: 'superscript',  label: 'Superscript (A²)'},
			// {command: 'sub', 			exec: 'subscript',    label: 'Subscript (A₂)'},
			{command: 'lineated', 	    exec: 'lineatetext',  label: 'Lineated (poetry)'},
			// {command: 'hr', type: 'hr'},
			// {command: 'small-caps', 	exec: 'smallcaps',    label: 'Small Caps'}
		],
		borderRadius: 'radius-all',
		size    : 3,
		closeIfUnavailable: false,
	},
	{
		name    : 'hr',
		label   : <HRIcon />,
		window  : false,
		tooltip : 'Horizontal Rule',
		size    : 1,
		closeIfUnavailable: false,
	},
	{
		name    : 'mediaoptions',
		label   : <MediaOptionsIcon />,
		window  : true,
		tooltip : 'Effects',
		positionType: 'center-under-button',
		borderRadius: 'radius-all',
		size    : 1,
		closeIfUnavailable: true,
		clickoutLayer: true,
	},
	{
		name    : 'textoptions',
		label   : <TextOptionsIcon />,
		window  : true,
		tooltip : 'Text Options',
		positionType : 'center-under-button',
		borderRadius: 'radius-all',
		size    : 1,
		closeIfUnavailable: true,
	},

	// {
	// 	name    : 'textcolor',
	// 	label   : <TextColorIcon />,
	// 	window  : true,
	// 	tooltip : 'Text Color',
	// 	positionType : 'center-under-button',
	// 	borderRadius: 'radius-all',
	// 	size    : 1
	// },
	{
		name    : 'bold',
		type 	: 'hidden',
		// label   : <BoldIcon />,
		window  : false,
		tooltip : 'Bold text',
		size    : 1,
		shortcut: 'cmd+b',
		closeIfUnavailable: false,
	},
	{
		name    : 'underline',
		type 	: 'hidden',
		shortcut: 'cmd+u',
	},
	{
		name    : 'italic',
		type 	: 'hidden',
		// label   : <ItalicIcon />,
		window  : false,
		tooltip : 'Italic text',
		size    : 1,
		shortcut: 'cmd+i',
		closeIfUnavailable: false,
	},
	{
		name    : 'textstyles',
		label   : 'Bodycopy',
		window  : false,
		tooltip : 'Text Style',
		// positionType: 'center',
		type 	: 'context-menu-select',
		borderRadius: 'radius-all',
		size    : 3,
		closeIfUnavailable: false,
	},
	{
		name     : 'textalignment',
		type     : 'button-context-menu',
		window   : false,
		tooltip  : 'Text Alignment',
		label    : <AlignLeftIcon />,
		size     : 1,
		header   : false,
		options  : [
			{command: 'alignleft',   exec: 'alignleft',   label: <AlignLeftIcon />, type: 'button'},
			{command: 'hr', type: 'hr'},
			{command: 'aligncenter', exec: 'aligncenter', label: <AlignCenterIcon />, type: 'button'},
			{command: 'hr', type: 'hr'},
			{command: 'alignright',  exec: 'alignright',  label: <AlignRightIcon />, type: 'button'}
		],
		borderRadius: 'radius-all',
		closeIfUnavailable: false,
		offset: {x: -15, y: 35}
	},
	{
		name    : 'link',
		label   : <LinkIcon />,
		window  : true,
		tooltip : 'Link<em>⌘K</em>',
		positionType: 'center-under-button',
		borderRadius: 'radius-all',
		size    : 1,
		shortcut: 'cmd+k',
		closeIfUnavailable: true,
		closePrioritizedByCommand: true,
	},
	{
		name    : 'layouttemplate',
		label   : 'Layout...',
		window  : false,
		// tooltip : 'Layout Options',
		// positionType: 'center',
		type 	: 'context-menu-select',
		borderRadius: 'radius-all',
		size    : 3,
		closeIfUnavailable: false,
		offset: {x: 26, y: 28}
	},
	{
		name    : 'columns',
		label   : <ColumnsIcon />,
		window  : true,
		type 	: 'hidden',
		tooltip : 'Columns',
		positionType: 'center-under-button',
		borderRadius: 'radius-all',
		size    : 1,
		closeOnAllClickout: true,
		closeIfUnavailable: false,
	},
	{
		name    : 'columns-ineditor',
		type 	: 'hidden',
		label   : <ColumnsIcon />,
		window  : true,
		tooltip : 'Columns',
		positionType: 'center-under-button',
		borderRadius: 'radius-all',
		size    : 1,
		closeOnAllClickout: true,
		closeIfUnavailable: false,
	},
	{
		name    : 'imagegallery',
		label   : <ImageGalleryIcon />,
		window  : true,
		type 	: 'hidden',
		tooltip : 'Gallery',
		positionType: 'center-under-button',
		borderRadius: 'radius-all',
		size    : 1,
		closeIfUnavailable: false,
	},
	{
		name    : 'thumbnailindex',
		label   : <ThumbnailIndexIcon />,
		window  : false,
		type 	: 'hidden',
		tooltip : 'Index',
		// positionType: 'center-under-button',
		// borderRadius: 'radius-all',
		// size    : 1,
		// closeIfUnavailable: false,
	},
	{
		name    : 'imagegallery-inedtior',
		type 	: 'hidden',
		label   : <ImageGalleryIcon />,
		window  : true,
		tooltip : 'Gallery',
		positionType: 'center-under-button',
		borderRadius: 'radius-all',
		size    : 1,
		closeIfUnavailable: false,
	},
	{
		name    : 'loremipsum',
		type 	: 'hidden',
		label   : null,
		window  : false,
		tooltip : '',
		positionType: 'center-under-button',
		size    : 1,
		closeIfUnavailable: false,
	}
]