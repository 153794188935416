import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";

import { FRONTEND_DATA } from "../../globals";
import _ from 'lodash';

import formattingWindowImports from '../top-menu-bar/formatting-ui-windows/imports';

// this is a component that allows components in the Frontend to interact with ui windows
class UIWindowOpener extends Component {
	constructor(props){
		super(props);
		this.clientFrameRect = null;
	}

	render(){
		return null;
	}

	getClientFrameRect = ()=>{

		clearTimeout(this.newRectTimeout);

		if (  !this.clientFrameRect ){
			const clientFrame = document.querySelector('#client-frame');
			this.clientFrameRect = clientFrame.getBoundingClientRect();
		}

		this.newRectTimeout = setTimeout(()=>{
			this.clientFrameRect = null;
		}, 500)
		
		return this.clientFrameRect;
	}

	shiftFrontendRectWithClientFrameRect = (frontendRect)=>{

		let clientFrameRect = this.getClientFrameRect();

		return {
			x: frontendRect.left + clientFrameRect.left,
			y: frontendRect.top + clientFrameRect.top,
			top: frontendRect.top + clientFrameRect.top,
			right: frontendRect.right,
			bottom: frontendRect.bottom,
			left: frontendRect.left + clientFrameRect.left,
			height: frontendRect.height || 0 ,
			width: frontendRect.width || 0,
		}

	}

	closeUIWindow = (windowName)=>{
		this.props.removeUIWindow(uiWindow => {
   			return uiWindow.id === 'formatting/'+windowName;
   		});
	}

	updateUIWindow = (windowName, props, rectsThatRequireShifting = [])=>{

		rectsThatRequireShifting.forEach((propName)=>{
			props[propName] = this.shiftFrontendRectWithClientFrameRect(props[propName]);
		});

		this.props.updateUIWindow('formatting/'+windowName, props);	

	}

	openUIWindow = (options)=>{

		const clientFrame = document.querySelector('#client-frame');
		const newRect = this.shiftFrontendRectWithClientFrameRect(options.positionRect);

		// if we're going from, for example - image option to image option, close the active window, wait for it to unmount, then add again
		let foundPreexistingWindow = false;
		this.props.removeUIWindow(uiWindow => {
   			if( uiWindow.id === 'formatting/'+options.windowName){
   				foundPreexistingWindow = true;
   				return true;
   			}
   			return false;
   		});

   		const windowImport = formattingWindowImports[options.windowAlias ? options.windowAlias : options.windowName]?.();

   		if(!windowImport) {
   			console.error(`Unable to find window with name "${options.windowAlias ? options.windowAlias : options.windowName}"`);
   			return;
   		}
	
		setTimeout(()=>{
			this.props.addUIWindow({
			 	group: 'formatting',
			 	component: windowImport,
			 	id: `formatting/${options.windowName}`,
			 	props: {
			 		type         		: 'popover',
			 		buttonPos    		: newRect,
			 		windowName   		: options.windowName,
			 		autoHeight   		: null,
			 		positionType 		: 'from-button',
			 		borderRadius 		: 'radius-all',
			 		maxHeight    		: null,
			 		ignoreEditorWindowClickout: options.ignoreEditorWindowClickout ?? true,
			 		closeOnAllClickout: options.closeOnAllClickout ?? false,
			 		closeOnSingleClickout: false,
			 		ignoreSessionPosition: true,
			 		closeButton: options.closeButton ?? false,
			 		supportsMobile: options.supportsMobile ?? false,
					waitForHeightBeforeRender: true,
					minimumRenderHeight: 50,
			 		...options.props
			 	}
			});
		},(foundPreexistingWindow ? 80 : 0))

	}

	componentDidMount(){
		window.UIWindowOpener = this
	}
}


function mapReduxStateToProps(state, ownProps) {
	return {
		adminState: state.adminState,
		activeWindow: _.map(state.uiWindows.byGroup['formatting'], uiWindowID => state.uiWindows.byId[uiWindowID])[0]
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateUIWindow: actions.updateUIWindow,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);
}

export default connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(UIWindowOpener);