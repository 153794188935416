import React, { useContext, useEffect, useMemo, useState } from 'react';
import { EditorContext } from "../page-editor";
import { commands } from "../../lib/inline-editor";
import _ from 'lodash';
import { connect } from 'react-redux';
import {actions, actionTypes} from "../../actions";
import { bindActionCreators } from 'redux';
import { ContextMenuButton } from "@cargo/common/context-menu";
import { adminCommerceProductFetchHelper } from "../ui-kit/helpers";


const CommerceOptionsContextUI = ({...props}) => {

	const [ranInitialProductFetch, setRanInitialProductFetch] = useState(false);

	useEffect(()=>{
		// if(!ranInitialProductFetch && props.hasCommerceShop) {
		// 	props.fetchCommerceProducts({
		// 		limit: 999
		// 	}).then(() => {
		// 		setRanInitialProductFetch(true);
		// 	})
		// }
		if( props.hasCommerceShop && !ranInitialProductFetch ){
			adminCommerceProductFetchHelper(props.productFetchRequired, props.fetchCommerceProducts, setRanInitialProductFetch, props.updateAdminState);
		}

	})

	const editorContext = useContext(EditorContext);

	const productList = props.products ? Object.values(props.products).sort((a,b) => a.sort_index - b.sort_index).reverse() : [];

	return (
		<>
		{ranInitialProductFetch ?
			<>

			<ContextMenuButton
				label={`Select a product...`}
				value="default"
				key={'formatting-commerce-product-list-header-01'} 
				disabled={true}
			/>

			{ productList.map(product => {

				return ( 
					<ContextMenuButton
						label={product.name || 'Untitled Product'}
						value={product.product_id+' '+product.variants[0].variant_id}
						key={product.product_id+'single'} 
						useClickToReleaseDelay={true}
						onPointerUp = { e => {
							commands['commerce'].execute({product: product.product_id, variant: product.variants[0].variant_id});
						}}
					/>
				);

// 				return ( 
// 					<>
// 					{product.variants.length === 1 ? (
// 					<>
// 						<ContextMenuButton
// 							label={product.name || 'Untitled Product'}
// 							value={product.product_id+' '+product.variants[0].variant_id}
// 							key={product.product_id+'single'} 
// 							useClickToReleaseDelay={true}
// 							onPointerUp = { e => {
// 								commands['commerce'].execute({product: product.product_id, variant: product.variants[0].variant_id});
// 							}}
// 						/>
// 
// 					</>
// 				) : (
// 					<>
// 						<ContextMenuButton
// 							label={product.name || 'Untitled Product'}
// 							value={product.product_id+' '+product.variants[0].variant_id}
// 							disabled={true}
// 							key={product.product_id+'header'}
// 							useClickToReleaseDelay={true}
// 							onPointerUp = { e => {
// 								commands['commerce'].execute({product: product.product_id, variant: product.variants[0].variant_id});
// 							}}
// 						/>
// 
// 						{product.variants.map( variant => {
// 							return (
// 
// 								<ContextMenuButton
// 									label={`${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${ variant.name || 'Untitled Variant' }`}
// 									value={variant.product_id+' '+variant.variant_id}
// 									key={variant.product_id+variant.variant_id} 
// 									useClickToReleaseDelay={true}
// 									onPointerUp = { e => {
// 										commands['commerce'].execute({product: variant.product_id, variant: variant.variant_id});
// 									}}
// 								/>
// 
// 							)
// 						})}
// 						<ContextMenuButton
// 							label={``}
// 							key={product.product_id+'spacer'}
// 							disabled={true}
// 						/>
// 					</>
// 				)}
// 				</>
// 				)
			})} 
			</>
		: 
			<ContextMenuButton 
				label={`Loading Products...`}
				disabled
			/>
		}
		</>
	)

};


function mapReduxStateToProps(state, ownProps) {

	return {
		hasCommerceShop: state.site.shop_id !== null,
		products: !_.isEmpty(state.commerce.products) ? state.commerce.products : null,
		productFetchRequired: state.adminState.productFetchRequired,
	};
}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({ 
		fetchCommerceProducts: actions.fetchCommerceProducts,
		updateAdminState: actions.updateAdminState,
	}, dispatch);

}

export default connect(
	mapReduxStateToProps,
	mapDispatchToProps
)(CommerceOptionsContextUI);
