import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";
import { MoreButton } from "../ui-kit";
import { Button } from "@cargo/ui-kit";
import PageListIcon from "../../svg-icons/pages.svg";

import { MenuContext } from "@cargo/common/context-menu";
import TitleBarContextUI from "./title-bar-context-ui";

import { paths } from "../../router";
import { matchPath } from "react-router";
import { withRouter} from 'react-router-dom';
import _ from 'lodash';
import { Formik, Field } from 'formik';
import FormikChangeListener from "../ui-kit/formik-change-listener";
import PageListItemContextUI from "./page-list-item-context-ui";
import { updateContentTitle } from "../../lib/page-list-manager";

class PageTitleBar extends Component {
	constructor(props) {

		super(props);
		this.ref = React.createRef();
	}

	navigate(path){
		this.props.history.push(path)
	}

	openPageSettings = (e) => {
		this.props.addUIWindow({
			group: 'main',
			component: import('../right-menu-bar/page-settings-window'),
			id: 'page-settings-window',
			props: {
				type: 'popover', 
				positionType: 'from-button',
				buttonPos: this.ref?.current?.getBoundingClientRect(),
				buttonOffset: {
					top: 9,
					right: -70 // offset from page list window is right: -338
				},
				borderRadius: 'radius-all', 
				autoHeight: true,
				pageID: this.props.page.id,
				windowName: 'page-settings',
				// closeOnSingleClickout: true,
				ignoreSessionPosition: true,
				additionalMarginBottom: 7,
				closeCallback: (lastClickCoordinates) => {
				}
			}
		},{
			removeAll: true
		})

	}

	render() {

		const matchedRoute = _.get(this.props.matchedRoute, 'path');
		let windowOpen = this.hasOpenWindow('page-list-window');

		const pageListButton = (
			<Button 
				className={`pages-button ${ windowOpen ? '' : '' }${this.props.pageTitleBarTransition ? ' page-changed' : ''}${this.props.page === undefined ? ' filler' : ''}`}
				label={
					<>
						<PageListIcon/>
						<div className="page-title">{this.props.page?.title}</div>
					</>
				}
				uiWindowIsOpen = { windowOpen }
				tooltip="Pages"
				button-state={this.props.commerceWindowOpen ? 'unavailable' : null}
				onMouseDown={(e) => {
					// don't lose focus in editor
					e.preventDefault();

					if( this.props.commerceWindowOpen ){ return }
					// don't open if right clicking
					if (e?.nativeEvent?.which === 3) {
						return;
					}

					this.props.addUIWindow({
						group: 'main',
						id: 'page-list-window',
						component: import('./page-list-window'),
						props: {
							type: 'popover',
							fullHeight: true,
							positionType: 'fixed-right',
							borderRadius: 'radius-all',
							autoHeight: true,
							buttonPos: e.currentTarget.getBoundingClientRect(),
							windowName: 'page-list-window'
						}
					},{
						removeAll: true
					});

				}}
				
			/>
		)


		if (this.props.page === undefined) {
			return pageListButton;
		} else {

			return (

				<MenuContext.Consumer>
				{(Menu) =>

					<div 
						ref={this.ref}
						id="page-title-bar"
						onContextMenu={(e)=>{
							e.preventDefault();
							Menu.openMenu({
								innerUI: <PageListItemContextUI 
									site={this.props.site} 
									page={this.props.page}
									openPageSettings={this.openPageSettings}
									buttonOffset={{top: 9,right: -70}}
								/>,
								type: 'mouse',
								event: e,
								offset: {x: 0, y: 0},
								onClose: null
							})
						}}
					>


					{ pageListButton }

					{this.props.page ? 
						<></>
					: 
						<div className="filler"></div>
					}
				</div>

				}
				</MenuContext.Consumer>
			);

		}

		

	}

	hasOpenWindow(id) {

		return this.props.activeUIWindows
			.filter(UIWindow => UIWindow.id === id)
			.length > 0
	}

	onChange = (changes) => {

		if(changes.title) {
			updateContentTitle('page', this.props.pid, changes.title);
		}
	
	}


}

function mapReduxStateToProps(state, ownProps) {

	const pid = _.get(state.adminState.matchedRoute, 'params.pid');
	const page = state.pages.byId[pid];

	return {
		pid,
		page,
		matchedRoute 	: state.adminState.matchedRoute,
		pages           : state.pages, 
		site          	: state.site,
		frontendState 	: state.frontendState,
		activeUIWindows : _.map(state.uiWindows.byGroup['main'], uiWindowID => state.uiWindows.byId[uiWindowID]),
		uiWindows     	: state.uiWindows,
		pageTitleBarTransition : state.adminState.pageTitleBarTransition,
		commerceWindowOpen: state.uiWindows.byId?.['commerce-window'] ? true : false,
	};

}

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		updateAdminState  : actions.updateAdminState,
		addUIWindow       : actions.addUIWindow,
		removeUIWindow    : actions.removeUIWindow,
		updatePage        : actions.updatePage
	}, dispatch);

}

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(PageTitleBar));
