import React, { Component } from 'react';
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox, ContextMenu, MenuContext } from "@cargo/common/context-menu"
import { FRONTEND_DATA } from "../../globals";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";

import imageoptions from "../../lib/inline-editor/commands/imageoptions";
import imagegallery from "../../lib/inline-editor/commands/imagegallery";

import _ from 'lodash';


class FigureContextMenu extends Component {

	constructor(props){
		super(props);
	}

	render(){

		const {
			mediaItemEditorInfo
		} = this.props;


		const galleryMenus = Object.entries(FRONTEND_DATA.galleryData).map(([name, layoutMode])=>
		<ContextMenuButton
			key={layoutMode.displayName}
			label={layoutMode.displayName}
			onPointerUp={ (e) => {
				this.createGallery(layoutMode.tagName)
			}}
		/>)

		return <>
			<ContextMenuButton 
				label="Options"
				onPointerUp={this.openMediaOptions}
			/>
			<ContextMenuButton 
				label="Reset all options"
				onPointerUp={this.resetOptions}
			/>			
			{!mediaItemEditorInfo.galleryParent &&
			<ContextSubMenu
				layer={1}
				label="Make Gallery"
			>
				{galleryMenus}
			</ContextSubMenu>}
			<ContextMenuButton 
				label="Edit Caption"
				onPointerUp={this.editCaption}
			/>
		</>
	}

	editCaption =(e) =>{
		// need to do this on the frontend for focus manipulation + scrollIntoView
		const {
			element
		} = this.props;

		element?._editorInterface?.setState({pointerAttention: false})
		element?._editorInterface?.editCaption();

	}

	createGallery = (galleryTagName)=>{
		const {
			element
		} = this.props;

		// if multiple media figures are selected inside editor, then let them be part of the selection
		let range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();
		const mediaItems = range.getNodes([Node.ELEMENT_NODE], (el)=>el.nodeName=="MEDIA-ITEM" );

		// otherwise, select the figure that this context menu is attached to
		if( mediaItems.length == 0 || !mediaItems.includes(element)){

			const media = element?.querySelector('.sizing-frame *');
			if( !media){
				return;
			}
			FRONTEND_DATA.contentWindow.CargoEditor.helpers.selectNode(element.children[0]);
		}

		imagegallery.execute(galleryTagName);

	}

	resetOptions =(e)=>{
		const {
			element
		} = this.props;

		let targetAttrs = 0;
		while(element.attributes.length > 0){
		    elem.removeAttribute(elem.attributes[0].name);
		}
	}

	openMediaOptions = (e)=>{

		const {
			element,
			elementRect,
			addUIWindow
		} = this.props;

		const media = element?.querySelector('.sizing-frame *');
		if( !media){
			return;
		}

		element?._editorInterface?.setState({pointerAttention: false})

		FRONTEND_DATA.contentWindow.CargoEditor.helpers.selectNode(element.children[0]);


		let xPos = 0;
		let windowWidth = document.documentElement.clientWidth;

		//if on left side, put it to the right
		if ( elementRect.left + elementRect.width + 410 < windowWidth ){
			xPos = elementRect.left + elementRect.width + 40

		// or put it on the left if there's room
		} else if (elementRect.left -410 > 0) {
			xPos = elementRect.left -410

		// or just put it over the figure if there's no room otherwise
		} else {
			xPos = Math.max(elementRect.left + elementRect.width*.5 + -185, 0)
		}

		let positioningRect = {
			bottom: elementRect.top,
			left: xPos,
			x: xPos,
			top: elementRect.top,
			y: elementRect.top,
			width: 0,
			height: 0,
		}


		// uncommenting this makes button state correct but also can cause incorrect selection
		// FRONTEND_DATA.contentWindow.CargoEditor.events.trigger('cursor-activity', FRONTEND_DATA.contentWindow.CargoEditor.getActiveEditor() );
		addUIWindow({
			group: 'formatting',
			component: import('./../top-menu-bar/formatting-ui-windows/imageoptions'),
			id: `formatting/imageoptions`,
			props: {
				type         		: 'popover',
				buttonPos    		: positioningRect,	
				windowName   		: 'imageoptions',
				positionType: 'from-button',
				borderRadius: 'radius-all',				
			}
		})		
	}

}


function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);
}

export default connect(
	null, 
	mapDispatchToProps
)(FigureContextMenu);
