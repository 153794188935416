import React, { useContext, useMemo } from 'react';
import { EditorContext } from "../page-editor";
import { commands } from "../../lib/inline-editor";
import _ from 'lodash';
import { connect } from 'react-redux';

import { MoreButton } from "../ui-kit";
import { Alert, AlertContext, Button } from "@cargo/ui-kit";

import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";

const FormattingTextOptionsContextUI = ({ options, ...props}) => {

	const editorContext = useContext(EditorContext);

	const renderedOptions = useMemo(()=> {

		const availableCommands = editorContext.range.commands;
		/* Add an isApplied , isAllowed to the option object 
		so we can tell which command is applied / allowed in the list.
		Also include the correct command name here (i.e. "sup" => "superscript");
		*/
		return options.map(option => {

			const associatedCommand = availableCommands[option.exec];
			return {
				...option,
				associatedCommand,
			}
		})
	}, [options, editorContext])

	return (
		<>
			{props.header ? 
				<>
					<ContextMenuButton 
						label={props.header}
						disabled
					/>
					{/* <hr className="invisible"/> */}
				</>
			: null}
			{_.map(renderedOptions, (option, index) => {
				if (option.type === 'hr') {
					return <hr key={index} />

				} else if (option.type === 'button') {
					return (<ContextMenuButton
						label={option.label}
						value={option.associatedCommand?.isApplied}
						disabled={!option.associatedCommand.isAllowed}
						key={index}
						useClickToReleaseDelay={true}
						onPointerUp = { e => {
							commands[option.exec].execute();
						}}
					/>)
				} else {
					return (<ContextMenuCheckbox
						label={option.label}
						value={option.associatedCommand?.isApplied}
						disabled={!option.associatedCommand.isAllowed}
						key={index}
						onPointerUp = { e => { 
							commands[option.exec].execute();
						}}
					/>)
				}
				
			})}
			
		</>

	);

};

export default FormattingTextOptionsContextUI;
