import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";
import * as sessionActions from "../../actions";
import { Alert, AlertContext, Button, Message, MessageContext } from "@cargo/ui-kit";
import { HotKeyProxy } from '../ui-kit';
import PageTitleBar from "./page-title-bar";
import { withRouter} from 'react-router-dom';
import { paths } from "../../router";
import { FRONTEND_DATA, PublishState } from "../../globals";
import _, { set } from 'lodash';
import { getSupportedFileTypes } from "../../components/ui-kit/helpers";
import { uploadMedia} from '../../lib/media';
import LocalPageDesignOptionsIcon from "../../svg-icons/local-page-design-options.svg";
import CodeViewIcon from "../../svg-icons/code-view.svg";
import ImageIcon from "../../svg-icons/image.svg";
import { getCRDTItem } from "../../lib/multi-user/redux";
import { WEB_HOSTNAME, helpers } from '@cargo/common';
import PointerIcon from "@cargo/common/icons/pointer.svg";
import { MenuContext, ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";

import { isMac } from "@cargo/common/helpers";

class Navigation extends Component {
	constructor(props) {

		super(props);

		this.state = {
			isDraggingOverMedia: false,
		}

		this.navigationRef = React.createRef();
		this.isMac = isMac();
	}

	hasOpenWindow(id) {

		return this.props.activeUIWindows
			.filter(UIWindow => UIWindow.id === id)
			.length > 0
	}


	removeAllUIWindows() {

		this.props.removeUIWindow(UIWindow => {
			return true;
		});
		
	}

	closeAdmin(){
		window.location.pathname = '/';
	}

	isEditingPage() {
		return this.props.PIDBeingEdited
	}

	// temporary method
	isOnHomePage() {
		return FRONTEND_DATA.history.location.pathname == '/';
	}

	hasOpenActiveWindow = (windowName) => {
		const currentPageOptionsUIWindow = this.props.openUIWindows[windowName];
		return currentPageOptionsUIWindow !== undefined;
	}

	toggleUIWindow = async (event, options) => {

		// Disable commerce clickout to close
		if( this.props.commerceWindowOpen ){ return }

		await this.props.addUIWindow({
			group: 'right-menu-bar',
			component: options.component,
			id: `${options.componentName}`,
			props: {
				type: options.uiWindowType, 
				positionType: options.positionType, 
				borderRadius: options.borderRadius, 
				autoHeight: options.autoHeight, 
				buttonPos: options.buttonPos ?? _.merge({}, event.currentTarget.getBoundingClientRect()), 
				tabbed: options.tabbed,
				windowName: options.windowName,
				clickoutLayer: options.clickoutLayer,
				clickoutLayerDim: options.clickoutLayerDim,
				preventClickout: options.preventClickout,
				preventEsc: options.preventEsc,
				acceptDrops: options.acceptDrops,
				className: options.className,
				draggingUploadedImage: false,
				disableDragging: options.disableDragging,
				supportsMobile: options.supportsMobile,
				closeButton: options.closeButton,
				legacyWindow: options.legacyWindow,
				styles: options.styles,
				proxiedModal: options.proxiedModal,
				proxiedMenu: options.proxiedMenu,
				ignoreClickout: options.ignoreClickout,
				mediaType: options.mediaType,
			}
		},{
			removeGroup: options.removeGroup ?? true,
			removeAll: options.removeAll ?? false 
		});

		// Always remove the color picker. 
		this.props.removeUIWindow(uiWindow => { 
			return uiWindow.id === 'color-palette' 
		});

		if (options.uiWindowType === 'popover' && options.removeGroup !== false && options.removeAll !== false) {

			this.props.removeUIWindow(uiWindow => {
				return uiWindow.id !== options.componentName && window.id !== options.allowedWindow && uiWindow.group === 'right-menu-bar' || uiWindow.group === 'main';
			});

		}


	}

	hasActiveWindow(windowName) {
		return _.filter(this.props.openUIWindows, {id: windowName}).length !== 0
	}

	delayPreviewForCommerceSave() {
		if( this.props.shopId && this.props.commerceWindowOpen ){

			if( window.Cargo?.Ecommerce?.View?.currentView?.saveButton?.saveEnabled === true || window.Cargo?.Ecommerce?.View?.currentView?.SaveButton?.saveEnabled === true ){
				// If there are unsaved commerce changes, we need to prompt the user to save before closing the window.
				// This event sends the "open ui window" info to the c2-c3 view controller, which will open the window after
				// the save modal is cleared.
				window.dispatchEvent(
					new CustomEvent('await-c3-ui-window-open', {
						detail: { uiWindow: null, options: 'preview' }
					})
				);

				return true
			}
		}

		return false
	}

	setSessionStorage = value => {
	    window.store.dispatch({
	        type: sessionActions.actionTypes.UPDATE_ADMIN_STATE, 
	        payload: {
	            session: {
	                [value]: true
	            }
	        }
	    });
	};

	getSessionStorage = () => {
	    const sessionState = window.store.getState().adminState.session;
	    return sessionState['preview-notice-seen'] ?? null;
	}

	render() {

		let exitURL = helpers.isLocalEnv ? `https://${this.props.site.site_url}.${WEB_HOSTNAME}` : this.props.site.direct_link;
		let isEditingPage = this.isEditingPage();
		const isViewportMobile = this.props.viewport === 'mobile' ? true : false ;

		return (
			<div id="navigation" ref={this.navigationRef}>
				<>
					<Button 
						label={<CodeViewIcon />}
						button-name="code-view-button"
						button-state={(this.props.commerceWindowOpen || !this.props.page) ? 'unavailable' : null}
						className={`formatting-button${this.hasOpenActiveWindow('page-code-view-window') ? ' popover-open' : ''}`}
						onMouseDown={(e) => {

							if(e.target.getAttribute('button-state') === 'unavailable') {
								return;
							}

							// prevent default so focus is not lost in the editor
							e.preventDefault();

							let buttonPos = e?.target.getBoundingClientRect();
							if( this.props.commerceWindowOpen ){ return }
							this.props.addUIWindow({
								group: 'main',
								component: import('../right-menu-bar/page-code-view-window'),
								id: `page-code-view-window`,
								props: {
									buttonPos: buttonPos,
									type: 'code-pane', 
									tabbed: true,
									positionType: 'center-under-button', 
									windowName: 'code-view',
									waitForHeightBeforeRender: true
									// preventEsc: true,
								}
							},{
								removeGroup: false,
								removeAll: true
							});
						}}
						tooltip="Code View"
					/>

					<Button 
						label={<LocalPageDesignOptionsIcon />}
						className={`formatting-button ${this.hasOpenActiveWindow('local-page-settings-window') ? 'popover-open' : ''}`}
						uiWindowIsOpen = { this.hasOpenActiveWindow('local-page-settings-window') }
						button-state={this.props.page ? this.props.hasLocalCSS ? 'in-use' : '' : 'unavailable'}
						onMouseDown={(e) => {

							if(e.target.getAttribute('button-state') === 'unavailable') {
								return;
							}

							// prevent default so focus is not lost in the editor
							e.preventDefault();

							let buttonPos = e?.target.getBoundingClientRect();
							if( this.props.commerceWindowOpen ){ return }
							this.props.addUIWindow({
								group: 'right-menu-bar',
								component: import('../right-menu-bar/page-settings-window-controller'),
								id: `local-page-settings-window`,
								props: {
									buttonPos: buttonPos,
									type: 'popover', 
									styles: 'page',
									tabbed: false,
									positionType: 'center-under-button', 
									windowName: 'local-page-settings-window',
									waitForHeightBeforeRender: true
									// preventEsc: true,
								}
							},{
								removeGroup: false,
								removeAll: true
							});


						}}
						tooltip="Local Page Settings"
					/>

					<Button 
						label={<ImageIcon />}
						name="images"
						button-state={!isEditingPage ? 'unavailable' : ''}
						className={`${this.state.isDraggingOverMedia === true ? 'dropping' :  this.hasActiveWindow('media-window') ? ' popover-open' : ''} formatting-button`}
						uiWindowIsOpen = { this.hasActiveWindow('media-window') }
						onDragOver={(editor, e, dragData) => {

							let draggingOver = false;
							let canDrop = false;
							const target = this.navigationRef?.current?.querySelector('[name="images"]');

							if (e.toElement === target) {
								draggingOver = true;
							}

							if (dragData.inAdmin && dragData.fromOutside) {
								canDrop = true;
							}

							if ( draggingOver === true && canDrop === true ) {
								this.setState({isDraggingOverMedia: true});
							} else {
								this.setState({isDraggingOverMedia: false});
							}

						}}
						onDragLeave={() => this.setState({isDraggingOverMedia: false})}
						onDrop={(editor, e, dragData) => {
							if (this.state.isDraggingOverMedia === true && dragData.inAdmin && dragData.fromOutside) {
								this.setState({isDraggingOverMedia: false});
								

								let droppedFiles = Array.from(dragData.dataTransfer.get('files'));

								let fileLibraryFiles =[];

								droppedFiles = droppedFiles.filter(file=>{

									const toFileLibrary = file.size /1048576 > 25 && file.type.startsWith('video')
									if (toFileLibrary){
										fileLibraryFiles.push(file);
									}
									return !toFileLibrary
								})
						
								// move larger files into the files library
								if( fileLibraryFiles.length > 0){

									setTimeout(()=>{
										if ( this.hasActiveWindow('library-window') === false ) {
											this.toggleUIWindow(null, {
												component: import('../right-menu-bar/library-window'),
												componentName: 'library-window',
												positionType: 'center',
												acceptDrops: true,
												allowedWindow: 'pageoptions-window',
												uiWindowType: 'popover',
												borderRadius: 'radius-all',
												className: 'files',
												closeOnSingleClickout: true,
												invokeTarget: null,
												invokeWindow: 'media-window',
												closeButton: false,
												buttonPos: false,
												removeGroup: false,
												avoidTransform: true,
												waitForHeightBeforeRender: true,
												minimumRenderHeight: 50,
											})
										}

										setTimeout(()=>{
											Promise.all(
												uploadMedia({
													target: getCRDTItem({ reducer: 'media' }),
													field: 'data'
												}, fileLibraryFiles)
											).then(result => {
												console.log(result, "Files added to page library!")
											});
										}, 250)
									}, 250)
								}

								droppedFiles.forEach(async (file) => {
									if ( getSupportedFileTypes('image').includes(file.type) || getSupportedFileTypes('video').includes(file.type) ) {
										if ( this.hasActiveWindow('media-window') === false ) {
											this.toggleUIWindow({currentTarget: e.target}, {
												component: import('../right-menu-bar/media-window'),
												componentName: 'media-window',
												uiWindowType: 'popover',
												windowName: 'media',
												positionType: 'center-under-button',
												acceptDrops: true,
												allowedWindow: 'pageoptions-window',
											});
										}
										uploadMedia({
											target: getCRDTItem({ reducer: 'pages.byId', item: this.props.PIDBeingEdited }),
											field: 'media'
										}, [file]);
									} else {
										setTimeout(()=>{
											this.toggleUIWindow(null, {
												component: import('../right-menu-bar/library-window'),
												componentName: 'library-window',
												buttonPos: false,
												uiWindowType: 'popover',
												positionType: 'center',
												waitForHeightBeforeRender: true,
												windowName: 'library',
												avoidTransform: true,
												minimumRenderHeight: 50,
												acceptDrops: true,
												allowedWindow: 'pageoptions-window',
												mediaType: 'files',
												removeGroup: false,
												className: 'files',
											})
											uploadMedia({
												target: getCRDTItem({ reducer: 'media' }),
												field: 'data'
											}, [file]);
										}, 250)
									}
								})
							}
						}}
						onMouseDown={(e) => {

							if(e.target.getAttribute('button-state') === 'unavailable') {
								return;
							}

							// prevent default so focus is not lost in the editor
							e.preventDefault();

							if( !isEditingPage ){ return }
							this.toggleUIWindow(e, {
								component: import('../right-menu-bar/media-window'),
								componentName: 'media-window',
								uiWindowType: 'popover',
								positionType: 'center-under-button',
								windowName: 'media',
								acceptDrops: true,
								allowedWindow: 'pageoptions-window'
							})
						}}
						tooltip="Images & Files"
					/>

					<PageTitleBar />
					
					<div className="button-group">
						<MenuContext.Consumer>
							{(Menu) => 
								<Button 
									className={`square exit ignore-clickout`} 
									button-state={this.props.commerceWindowOpen ? 'unavailable' : null}
									// button-state={`${this.props.matchedRoute === paths.COMMERCE ? 'unavailable' : ''}`}
									icon={<PointerIcon />}
									tooltip={this.isMac ? "Preview Draft<em>⌘<span>Esc<span></em>" : null}
									onMouseDown={ e => {

										// prevent default so focus is not lost in the editor
										e.preventDefault();

										// Disable commerce clickout to close
										if( this.props.commerceWindowOpen ){ return }
										// open the menu
										Menu.openMenu({
											type: 'button',
											event: e,
											offset: {x: 20, y: 8},
											innerUI: <>
												
												<ContextMenuButton 
													label={this.isMac ? ( <>Preview Draft<span className="shortcut">⌘ Esc</span></> ) : (<>Preview Draft</>)}
													onPointerUp = { e => { 

														if( this.delayPreviewForCommerceSave() ){ return }

														this.props.history.push('/preview');

														if( !this.getSessionStorage() ){
															setTimeout(()=>{
																this.props.updateAdminState({previewMessage: `Press Esc. to exit Preview`})	
																this.setSessionStorage('preview-notice-seen');
															}, 150)
															
														}
	
													}}
												/>
												<hr/>
												
												<AlertContext.Consumer>
												    {(Alert) => (
						    			    			
					    			    				<ContextMenuButton 
					    			    					label={<>Go to Site &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
															onPointerUp = { e => {

																let exitURL = helpers.isLocalEnv ? `https://${this.props.site.site_url}.${WEB_HOSTNAME}` : this.props.site.direct_link;

																if (e.metaKey === true || e.ctrlKey === true ) {
																	window.open(exitURL, '_blank');
																} else {
																	window.location = exitURL;
																	return;
																}

						    	            		    	}}
						    	            		    />

											    	)}
												</AlertContext.Consumer>    
	
											</>,
										}) 

										
									}}
								/>
						    }
						</MenuContext.Consumer>
					</div>
				</>
			</div>
		);

	}


}

function mapReduxStateToProps(state, ownProps) {
	const page = state.frontendState.PIDBeingEdited ? state.pages.byId[state.frontendState.PIDBeingEdited] : null;
	return {
		page,
		site: state.site,
		adminState: state.adminState,
		uiWindows: state.uiWindows,
		openUIWindows: state.uiWindows.byId,
		PIDBeingEdited: state.frontendState.PIDBeingEdited,
		activeUIWindows: _.map(state.uiWindows.byGroup['main'], uiWindowID => state.uiWindows.byId[uiWindowID]),
		viewport: state.adminState.viewport,
		hasLocalCSS: /{[^}]+}/.test(page?.local_css?.replace(/\s/g, '')),
		userMeta: state.user?.meta,
		userId: state.user?.id,
		matchedRoute: state.adminState.matchedRoute?.path,
		shopId: state.site.shop_id,
		commerceWindowOpen: state.uiWindows.byId?.['commerce-window'] ? true : false,
	};

}

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		updateAdminState: actions.updateAdminState,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow,
		updateUserMeta: actions.updateUserMeta
	}, dispatch);

}

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(Navigation));
