import axios from 'axios';
import { API_ORIGIN } from "@cargo/common";
import { duplicateContent } from '../page-list-manager';
import { store, history } from '../../index';
import { actions } from '../../actions';
import { globalUndoManager } from "../undo-redo";
import CargoCSSParser from "../shared-css/cargo-css-parser";
import { getCRDTItem, applyChangesToYType } from "../multi-user/redux";
import { CRDTState } from "../../globals";
import { PropWatcherManager } from "../shared-css/withPropWatchers";
import getLocalCSSParser from '../shared-css/get-local-parser';
import getGlobalCSSParser from '../shared-css/get-global-parser';
import { TextStyleDefaults } from "../../defaults/text-style-defaults";
import { subscriptions } from "../shared-css/global-css-subscriptions";
import * as Y from 'yjs'
import _ from 'lodash';

export const getShortCodeFromPasteEvent = event => {

	const pastedString = event.clipboardData.getData('text/plain')?.trim().replace(/[^\w\d,:?&= ]/g, '');

	if(pastedString && pastedString.startsWith('cargo:')) {
		return pastedString;
	}

	return false;

}

export const executeShortcode = async rawshortcode => {
	
	const PIDBeingEdited = store.getState().frontendState.PIDBeingEdited;

	globalUndoManager.pause();

	store.dispatch({
		type: 'UPDATE_ADMIN_STATE', 
		payload: {
			pauseGlobalEventExecution: true,
			adminLockedSpinner: true,
		}
	}); 

	try {

		// cargo:1805055:B1693608529?copybackdrop

		// cargo:1826554?copybackdrop
		const [ shortcode, optionsAsURLParams ] = rawshortcode.split('?');

		const options = Object.fromEntries(new URLSearchParams(optionsAsURLParams));

		let [ shortcodeIdentifier, siteId, pageIds = "" ] = shortcode.split(':');

		pageIds = pageIds.split(',').filter(pid => typeof pid === "string" && pid.length > 0);

		if(!siteId) {

			// example: cargo:?inject-font&name=Diatype Condensed Variable&styles=eyJmb250LWZhbWlseSI6IkRpYXR5cGUgQ29uZGVuc2VkIFZhcmlhYmxlIiwiZm9udC1zaXplIjoiNzBweCIsImZvbnQtd2VpZ2h0Ijo0MDAsImZvbnQtdmFyaWF0aW9uLXNldHRpbmdzIjoiJ3dnaHQnIDQwMCwgJ3dkdGgnIDc1LCAnc2xudCcgMCJ9
			if(options.hasOwnProperty('injectfont')) {

				// get local global stylesheet parser
				const globalCSSParser = getGlobalCSSParser();
				const injectedFontStyles = JSON.parse(atob(options.styles));

				const textStyleName = options.name.replace(/[\"]/g, '');
				// kill numbers and whitespace at the start of the class name (browser's wont let you do this) and replace spaces
				let textStyleClassName  = options.name
					.replace(/[^a-zA-Z-\d\s]/g, '')
					.replace(/^[\d\s]+/g, '')
					// replace spaces with dashes
					.replace(/\s/g, '-')
					// Replace multiple dashes with a single dash
					.replace(/-{2,}/g, '-')
					.toLowerCase();

				// first get a unique class name
				const allRules = globalCSSParser.getParsedRules();

				let hasCollision = true;
				let collisionCounter = 0;
				let uniqueClassName = `.${textStyleClassName}`;
				let uniqueTextStyleName = textStyleName;

				while(hasCollision) {

					let foundCollision = false;

					for(let i = 0; i < allRules.length; i++) {

						const selectorParts = allRules[i].parsedSelectors.map(s => s.content).join(' ').split(/\s+/);

						if(selectorParts.includes(uniqueClassName)) {

							uniqueClassName = `.${textStyleClassName}-${++collisionCounter}`
							uniqueTextStyleName = `${textStyleName} ${collisionCounter}`
							foundCollision = true;
							break;

						}

					}

					hasCollision = foundCollision;

				}

				// generate the CSS for the new text style
				const textStyleCSS = `

${uniqueClassName} {
	--text-style: "${uniqueTextStyleName}";
	font-family: "${injectedFontStyles['font-family']}";
	font-style: normal;
	font-weight: ${injectedFontStyles['font-weight']};
	font-size: ${injectedFontStyles['font-size']};
	line-height: 1;
	color: rgba(0, 0, 0, 0.85);
	letter-spacing: 0;
	${injectedFontStyles['font-variation-settings'] ? `font-variation-settings: ${injectedFontStyles['font-variation-settings']}` : ''};
}

${uniqueClassName} a {
	text-decoration: none;
}

${uniqueClassName} a:hover {
	
}`
				// find the last text style in the sheet
				const textStyleSelectors = [...TextStyleDefaults, ...Array.from(subscriptions.get("text-style-selectors"))].map(sub => sub.tag);

				const lastTextStyleRule = _.findLast(allRules, rule => {
					// check if the rule's selector matches any of our text style classNames
					return textStyleSelectors.find(selector => rule.parsedSelectors[0]?.content?.startsWith(selector))
				});

				// insert it into the stylesheet
				globalCSSParser.insertCSS(textStyleCSS, lastTextStyleRule?.parsedRule.end);

				if(!store.getState().uiWindows.byId.hasOwnProperty('text-styles-window')) {
					// open text styles window
					store.dispatch(
						actions.addUIWindow({
							group: 'right-menu-bar',
							component: import('../../components/right-menu-bar/text-styles-window'),
							id: 'text-styles-window',
							props: {
								type: 'popover',
								uiWindowType: 'popover',
								borderRadius: 'radius-all',
								// clickoutLayer: true,
								windowName: 'text-styles',
								closeOnSingleClickout: true,
								positionType: 'fixed-right',
								selectedTextStyle: _.last(subscriptions.get("text-style-selectors"))
							}
						},{
							removeGroup: true,
							toggle: false
						})
					);
				} else {
					// update text styles window to show latest style
					store.dispatch(
						actions.updateUIWindow('text-styles-window', {
							selectedTextStyle: _.last(subscriptions.get("text-style-selectors"))
						})
					);
				}

			}

		} else {

			if(pageIds.length === 0) {

				// try getting homepage
				const { data: externalSiteModel } = await axios.get(`${API_ORIGIN}/sites/${siteId}`, {
					use_auth: true
				});

				if(externalSiteModel.homepage_id) {
					pageIds.push(externalSiteModel.homepage_id);
				}

				if(pageIds.length === 0) {

					// try getting first editable page
					const { data: externalScaffolding = [] } = await axios.get(`${API_ORIGIN}/pages/${siteId}/scaffolding`, {
						use_auth: true
					});

					const pages = externalScaffolding.filter(content => content.page_type === "page");

					if(pages[0]?.id) {
						pageIds.push(pages[0]?.id)
					}

					if(pageIds.length === 0) {
						throw 'Unable to locate page...';
					}

				}

			}

			while(pageIds.length > 0) {

				// grab last page ID from the list
				const pageId = pageIds.pop();

				const { data: externalPageData } = await axios.get(`${API_ORIGIN}/pages/${siteId}/id/${pageId}`, {
					use_auth: true
				});

				if(!externalPageData || externalPageData.page_type !== "page") {
					throw 'Unable to locate page...';
				}

				// Delete fields not wanted in admin
				delete externalPageData.index;
				delete externalPageData.sort;
				delete externalPageData.set_id;
				delete externalPageData.is_homepage;

				if(externalPageData.overlay_options?.openOnLoad) {
					// force open on load to false for overlays. This is due to the design
					// lab needing openOnLoad: true to auto preview the overlays but we do not
					// want this to happen on the target site.
					externalPageData.overlay_options.openOnLoad = false;
				}

				// get local global stylesheet parser
				const globalCSSParser = getGlobalCSSParser();

				if(options.hasOwnProperty('copybackdrop')) {
					// cargo:1826554?copybackdrop

					if(!PIDBeingEdited) {
						throw 'No page is being edited...'
					}

					// merge foreign backdrop settings into local
					if(!externalPageData.backdrops?.activeBackdrop) {
						throw 'Unable to locate backdrop settings...';
					}
					
					if(externalPageData.backdrops?.activeBackdrop === 'none') {
						throw 'No backdrop set on source page...';
					}

					const { CRDTItem: localPageInDraft } = getCRDTItem({
						reducer: `pages.byId`,
						item: PIDBeingEdited
					});

					const localPageData = localPageInDraft.toJSON();
					
					// get some methods to help us setup local styles
					const { getPageBackdropCSSSelectors, getPageBackdropPropwatcherConfig } = await import('../../components/right-menu-bar/page-backdrop-window');

					ydoc.transact(() => {

						// make sure all media present on the foreign page is copied into our local page
						const localMediaHashes = localPageData.media.map(localMedia => localMedia.hash);

						externalPageData.media.forEach(externalMedia => {

							// already there
							if(localMediaHashes.includes(externalMedia.hash)) {
								return;
							}

							// create a YJS media model that will be publishable by the local site
							const mediaModel = applyChangesToYType(new Y.Map(), {
								...externalMedia,
								crdt_state: CRDTState.New
							});

							// insert the model into the local page
							localPageInDraft.get('media').push([mediaModel]);

						});

						// Make sure we have a backdrop settings object
						if(!localPageInDraft.get('backdrops').has('backdropSettings')) {
							localPageInDraft.get('backdrops').set('backdropSettings', new Y.Map());
						}

						// override active backdrop settings
						localPageInDraft.get('backdrops').get('backdropSettings').set(externalPageData.backdrops.activeBackdrop, applyChangesToYType(
								new Y.Map(), 
								externalPageData.backdrops.backdropSettings[externalPageData.backdrops.activeBackdrop]
							)
						)

						// set the new active backdrop
						localPageInDraft.get('backdrops').set('activeBackdrop', externalPageData.backdrops.activeBackdrop);
						
						// get parser for the local CSS so we can set the page to 100% height
						const localCSSParser = getLocalCSSParser(localPageData.id, localPageData.local_css);

						// spin up a manager to load all required propWatchers
						const propWatcherManager = new PropWatcherManager({
							parserType: 'local',
							parsers: {
								local: localCSSParser,
								global: globalCSSParser
							}
						});

						const pageBackdropSelectors = getPageBackdropCSSSelectors(localPageData.id);
						const pageBackdropPropwatcherConfig = getPageBackdropPropwatcherConfig(
							pageBackdropSelectors
						)

						// load the watcher config
						propWatcherManager.reloadWatchers(pageBackdropPropwatcherConfig);

						// set page height to 100%
						propWatcherManager.applyChanges({
							[pageBackdropSelectors.pageSelector] : {'min-height': 'var(--viewport-height)'},
							[pageBackdropSelectors.pageContentSelector] : {'align-items': propWatcherManager.propWatcherValues[pageBackdropSelectors.pageContentSelector]['align-items'] || 'flex-start'}
						});

					});

					// do not handle multiple pages when duplicating backdrop settings
					break;

				} else {
					// cargo:1826554

					// clone foreign page into site
					const duplicatedPage = await duplicateContent('page', externalPageData.id, {
						contentToDuplicate: externalPageData,
						title: externalPageData.title,
						customParentId: 'root',
						customSortIndex: 0
					});

					// get some methods to help us setup local styles for the cloned page
					const { getLocalStylesCSSSelectors, getLocalStylePropwatcherConfig } = await import('../../components/right-menu-bar/local-page-settings-ui');

					const { data: externalCSS } = await axios.get(`${API_ORIGIN}/sites/${siteId}/css`, {
						use_auth: true
					});

					const localStyleUICSSSelectors = getLocalStylesCSSSelectors(duplicatedPage.id);
					const localStylePropwatcherConfig = getLocalStylePropwatcherConfig(localStyleUICSSSelectors)

					const range = document.createRange();
					const duplicatedPageContentFrag = range.createContextualFragment(duplicatedPage.content);

					// get parser for global sheet of foreign site
					const externalGlobalCSSParser = new CargoCSSParser(new Y.Text(), externalCSS.stylesheet);

					// listen for text styles
					const textStyleNodes = Array.from(duplicatedPageContentFrag.querySelectorAll('a, span'));

					// see if any textStyleNodes in the duped content have a text style class
					if(textStyleNodes.length > 0) {

						externalGlobalCSSParser.subscribe('--text-style', event => {
							if(event.type === "added"){

								// wait a microtick so we don't run nested getParsedRules() calls
								Promise.resolve().then(() => {

									const selector = event.selector.content;
									const className = selector.replace(/^\./, '');
									const textStyleNode = textStyleNodes.find(span => span.classList.contains(className));
									const existingTextStyle = subscriptions.get("text-style-selectors").find(sub => sub.className === className);

									if(textStyleNode && !existingTextStyle) {

										let concatenatedRules = '';

										// the copied content has this text style applied to it. Copy it into the site
										externalGlobalCSSParser.getParsedRules([
											selector,
											selector + ' a',
											selector + ' a:hover',
											selector + ' a:active',
											selector + ' a.active',
											'.mobile ' + selector,
											'.mobile ' + selector + ' a',
											'.mobile ' + selector + ' a:hover',
											'.mobile ' + selector + ' a:active',
											'.mobile ' + selector + ' a.active'
										]).forEach(result => {
											concatenatedRules += `\n\n${result.parsedRule.content}`;
										});

										if(concatenatedRules.length > 0) {

											const allRules = globalCSSParser.getParsedRules();

											const textStyleSelectors = [...TextStyleDefaults, ...Array.from(subscriptions.get("text-style-selectors"))].map(sub => sub.tag);

											const lastTextStyleRule = _.findLast(allRules, rule => {
												// check if the rule's selector matches any of our text style classNames
												return textStyleSelectors.find(selector => rule.parsedSelectors[0]?.content?.startsWith(selector))
											});

											const fontFamilies = store.getState().fontCollection.unprocessedCollection.map(font => font.family_name);

											// Replace font-family with Diatype, if the font-family is not already in the local collection
											concatenatedRules = concatenatedRules.replace(/font-family: ([^;]+);/g, (match, fontFamily) => {
												const cleanFontFamily = fontFamily.replace(/"/g, '');
												if (!fontFamilies.includes(cleanFontFamily)) {
													return `font-family: Diatype Variable;`;
												}
												return match;
											});

											// insert it into the stylesheet
											globalCSSParser.insertCSS(concatenatedRules, lastTextStyleRule?.parsedRule.end);
										}
									}

								});

							}
						});

					}

					// get parser for the local CSS of our newly cloned page
					const localCSSParser = getLocalCSSParser(duplicatedPage.id, duplicatedPage.local_css);

					// spin up a manager to load all required propWatchers
					const propWatcherManager = new PropWatcherManager({
						parserType: 'local',
						parsers: {
							local: localCSSParser,
							global: externalGlobalCSSParser
						}
					});

					// load the watcher config for our new page with the globals of the foreign site
					propWatcherManager.reloadWatchers(localStylePropwatcherConfig);

					const localMinHeightPropertyWatcher = propWatcherManager.propWatcherMap[localStyleUICSSSelectors.pageSelector]['min-height'];
					const externalGlobalMinHeightPropertyWatcher = propWatcherManager.propWatcherMap[localStyleUICSSSelectors.pageSelector]['min-height'];
					let inheritVerticalAlignment = false;

					// only inherit align-items from the global sheet if min-height's parsed local value (which does not take in account inheritance) is null
					if(localMinHeightPropertyWatcher?.parsedValue === null) {
						
						inheritVerticalAlignment = true;

						if(
							// if local min-height === null and external global min-height === null 
							externalGlobalMinHeightPropertyWatcher?.parsedValue === null
							// and the watcher is not overwritten globally already
							&& !(localMinHeightPropertyWatcher.isInheriting && localMinHeightPropertyWatcher.propWatcherUsedForInheritance.parser === externalGlobalCSSParser)
						) {
							// then hard-set the local to min-height: auto
							localMinHeightPropertyWatcher.setValue('auto')
						}

					}

					// loop over all watchers and set the ones still inheriting from the foreign site's global CSS
					_.each(propWatcherManager.propWatcherMap, (watchers, selector) => {

						_.each(watchers, (watcher, property) => {

							// if a watcher is inheriting from the external stylesheet, copy it into the local CSS
							if(watcher.isInheriting && watcher.propWatcherUsedForInheritance.parser === externalGlobalCSSParser) {

								if (
									property === 'align-items' 
									&& watcher.selector === localStyleUICSSSelectors.pageContentSelector
									&& !inheritVerticalAlignment
								) {
									// skip 
									return;
								}

								// store value as it was when inheriting
								const value = watcher.value;

								// kill inheritance so we can set the value locally
								watcher.inheritFrom(null);

								// set the value locally
								watcher.setValue(value, {
									insertAtStart: true
								});

							}

						});
					});

					// only do this on the last page
					if(pageIds.length === 0) {
						history.push(`/${duplicatedPage.id}`);
					}

				}

			}

		}

	} catch(e) {

		console.error(e)

	}

	// wait for the microticks above to finish before restarting undo/redo
	Promise.resolve().then(() => {
		globalUndoManager.resume();
	});

	store.dispatch({
		type: 'UPDATE_ADMIN_STATE', 
		payload: {
			pauseGlobalEventExecution: false,
			adminLockedSpinner: false,
		}
	}); 

}