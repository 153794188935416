import React, { useState, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";
import { FormattingButtons } from "./formatting-buttons";
import FormattingButton from "./formatting-button";
import FormattingTextStylesSelect from "./formatting-text-styles-select";
import FormattingLayoutTemplateSelect from './formatting-layout-template-select';
import FormattingButtonContextMenu from "./formatting-button-context-menu";
import PageOptionsIcon from "../../svg-icons/formatting-page-options.svg";
import BackdropIcon from "../../svg-icons/backdrop.svg";
import FolderArrow from "@cargo/common/icons/folder-arrow.svg";
import { Alert, AlertContext, Button, Message, MessageContext } from '@cargo/ui-kit';
import { HOMEPAGE_ORIGIN } from "@cargo/common";
import { MenuContext, ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";
import { FRONTEND_DATA } from "../../globals";
import _ from 'lodash';
import { getSupportedFileTypes } from './../ui-kit/helpers';
import { commands } from '../../lib/inline-editor';

import formattingWindowImports from './formatting-ui-windows/imports';

class FormattingMenu extends Component {

	constructor(props) {
	    super(props);

	    this.formattingButtons = FormattingButtons;

	    this.state = {
	    	buttonsToRender : this.formattingButtons,
	    }

	    this.menuRef = React.createRef();

	}

	componentDidMount = () => {

		// after 3 seconds preload all formatting windows
		// like the link modal
		setTimeout(() => {
			this.preloadChunks();
		}, 3000);

	}

	preloadChunks = () => {

		const chunks = _.map(formattingWindowImports, importFn => importFn());

	}

	Button = (index) => {

		const data = this.state.buttonsToRender[index]

		const alternateCommand = data.name === "textformatting" 
				? ["lineatetext"] : data.name === "textcolor"
					? ["backcolor"] : undefined
			
			if (data.type === 'context-menu-select') {
				// CONTEXT MENU SELECT BUTTON
				if( data.name === 'layouttemplate' ){
					return (
						<FormattingLayoutTemplateSelect
							commandName  = { data.name } 
							// alternateCommand = { alternateCommand }
							label        = { 
												<>
													<span>{data.label}</span>
													<div className="select-arrows">
														<div></div>
													</div>
												</>
											}
							tooltip      = { data.tooltip ? data.tooltip : null }
							type         = { data.type }
							key          = { data.name+index }
							data 		 = { data }
						/>
					)
				}

				if( data.name === 'textstyles'){
					return (
						<FormattingTextStylesSelect
							commandName  = { data.name } 
							alternateCommand = { alternateCommand }
							label        = { 
												<>
													<span>{data.label}</span>
													<div className="select-arrows">
														<div></div>
													</div>
												</>
											}
							tooltip      = { data.tooltip ? data.tooltip : null }
							type         = { data.type }
							key          = { data.name+index }
							data 		 = { data }
						/>
					)
				}

			} else if(data.type === 'button-context-menu') {
				return (<FormattingButtonContextMenu
						commandName  = { data.name } 
						alternateCommand = { alternateCommand }
						label        = { data.label }
						tooltip      = { data.tooltip ? data.tooltip : null }
						data         = { data }
						type         = { data.type }
						key          = { data.name+index }
						options      = { data.options }
						name         = { data.name }
					/>
				)
			} else {

			// NORMAL BUTTON
				return (
					<FormattingButton 
						commandName  = { data.name } 
						alternateCommand = { alternateCommand }
						label        = { data.label }
						tooltip      = { data.tooltip ? data.tooltip : null }
						uiWindow     = { data.window ? true : false }
						key          = { data.name+index }
						requiresImageSelection = { data.requiresImageSelection ? '' : null }
						data         = { data }
						type         = { data.type }	
						closeIfUnavailable = { data.closeIfUnavailable }
					/>
				)
			}
	}

	hasPageOptionsApplied() {
		const state = commands['pageoptions'].getState();
		return state.isApplied;
	}

	hasOpenActiveWindow = (windowName) => {
		const currentPageOptionsUIWindow = this.props.openUIWindows[windowName];
		return currentPageOptionsUIWindow !== undefined;
	}

	navigateToHomepage = (e) => {

		if (e.ctrlKey === true || e.metaKey === true) {
			window.open(HOMEPAGE_ORIGIN, '_blank');
		// normal click = same window
		} else {
			window.location.href = HOMEPAGE_ORIGIN;
		}

	}

	render() {

		return (
			<div 
				id="formatting-menu"
				ref={this.menuRef}
			>
				<div className="account-area">
					<MenuContext.Consumer>
						{(Menu) => 
						<Button 
							label={
								<FolderArrow />
							}
							className={`go-to-sites`}
							onClick={(e) => {

								// if (e.metaKey === true) {
								// 	window.open(HOMEPAGE_ORIGIN, '_blank');
								// // normal click = same window
								// } else {
								// 	window.location.href = HOMEPAGE_ORIGIN;
								// }

							}}
							onMouseDown={e => {

								e.preventDefault();

								if(window.commerceConfirmUnloadPromise) {

										window.commerceConfirmUnloadPromise.then(() => {
											this.navigateToHomepage(e);
										}).catch(() => {});

								} else {
									this.navigateToHomepage(e);
								}

							}}						
							tooltip="Your Sites<em>⇧⌘1</em>"
							tooltipOffsetX={ 0 }
							tooltipOffsetY={ 0 }
						/>
				    }
				</MenuContext.Consumer>
				</div>

				<Button 
					label={<BackdropIcon />}
					button-state={
						this.props.PageBeingEditedData && !this.props.commerceWindowOpen ? 
							this.props.PageBeingEditedData?.backdrops && this.props.PageBeingEditedData?.backdrops?.activeBackdrop !== 'none' ? 'in-use' : ''
							: 'unavailable'
					}
					className={`formatting-button ${this.hasOpenActiveWindow('page-backdrop-window') ? 'popover-open' : ''}`}
					uiWindowIsOpen = { this.hasOpenActiveWindow('page-backdrop-window') ? true : false }
					onMouseDown={(e) => {

						if(e.target.getAttribute('button-state') === 'unavailable') {
							return;
						}

						// Disable commerce clickout to close
						if( this.props.commerceWindowOpen ){ return }
						
						let buttonPos = e?.target.getBoundingClientRect();

						this.props.addUIWindow({
							group: 'formatting',
							component: import('../../components/right-menu-bar/page-backdrop-window'),
							id: 'page-backdrop-window',
							props: {
								type: 'popover',
								positionType: 'center-under-button',
								buttonPos: buttonPos,
								preventEsc: true,
								windowName: 'backdrop-settings',
								acceptDrops: false,
								waitForHeightBeforeRender: true,
								minimumRenderHeight: 60,
							}
							
						},{
							removeGroup: true,
							removeAll: true
						})
					}}
					tooltip="Backdrop"
				/>

				<Button 
					label={<PageOptionsIcon />}
					button-state={ this.props.commerceWindowOpen && this.hasPageOptionsApplied()
						? 'in-use unavailable'
						: (this.props.commerceWindowOpen || !this.props.PageBeingEditedData)
						  ? 'unavailable'
						  : this.hasPageOptionsApplied()
							? 'in-use'
							: '' }
					className={`formatting-button ${this.hasOpenActiveWindow('pageoptions-window') ? 'popover-open' : ''}`}
					uiWindowIsOpen = { this.hasOpenActiveWindow('pageoptions-window') ? true : false }
					onMouseDown={(e) => {

						if(e.target.getAttribute('button-state') === 'unavailable') {
							return;
						}

						// Disable commerce clickout to close
						if( this.props.commerceWindowOpen ){ return }

						 let buttonPos = e?.target.getBoundingClientRect();

						this.props.addUIWindow({
							group: 'formatting',
							component: import('../../components/top-menu-bar/formatting-ui-windows/pageoptions'),
							id: 'pageoptions-window',
							props: {
								type: 'popover',
								positionType: 'center-under-button',
								buttonPos: buttonPos,
								preventEsc: true,
								windowName: 'pageoptions',
								acceptDrops: false,
								waitForHeightBeforeRender: true,
								minimumRenderHeight: 60,
							}
							
						},{
							removeGroup: true,
							removeAll: true
						})
					}}
					tooltip="Page Options"
				/>
				


				{this.state.buttonsToRender.map((button, index) => {
					return this.Button(index)
				})}

			</div>

		);
	}

}


function mapReduxStateToProps(state, ownProps) {

	const page = state.frontendState.PIDBeingEdited ? state.pages.byId[state.frontendState.PIDBeingEdited] : null;

	return {
		page,
		PIDBeingEdited: state.frontendState.PIDBeingEdited,
		openUIWindows: state.uiWindows.byId,
		PageBeingEditedData: state.pages.byId[state.frontendState.PIDBeingEdited],
		commerceWindowOpen: state.uiWindows.byId?.['commerce-window'] ? true : false,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);

}

export default connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(FormattingMenu);