import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { HotKey } from "./ui-kit";
import { Button } from "@cargo/ui-kit";
import { store } from "../index";
import { FRONTEND_DATA } from "../globals";
import CloseIcon from "../svg-icons/navigation-close.svg";
import { isMac } from "@cargo/common/helpers";

class PreviewButton extends Component {

	constructor(props) {

		super(props);

		this.pidToReturnTo = props.PIDBeingEdited;

		const renderedPageIds = props.renderedPages.map(el => el.id);

		if(
			// when entering preview mode
			this.props.previewing
			&& (
				// and we're editing something
				(
					props.PIDBeingEdited
					// and that something is a homepage
					&& (
						renderedPageIds.includes(this.props.homepageId)
						|| renderedPageIds.includes(this.props.mobileHomepageId)
					)
				) 
				// or we're not editing anything
				|| !props.PIDBeingEdited
			)
		) {

			// use the homepage path instead of a hardcoded PID path. That lets
			// preview switch between mobile and desktop homepages when resizing
			FRONTEND_DATA.history.replace('/');

		}

		this.isMac = isMac();

	}

	delayPreviewForCommerceSave() {
		if( this.props.shopId && this.props.commerceWindowOpen ){

			if( window.Cargo?.Ecommerce?.View?.currentView?.saveButton?.saveEnabled === true || window.Cargo?.Ecommerce?.View?.currentView?.SaveButton?.saveEnabled === true ){
				// If there are unsaved commerce changes, we need to prompt the user to save before closing the window.
				// This event sends the "open ui window" info to the c2-c3 view controller, which will open the window after
				// the save modal is cleared.
				window.dispatchEvent(
					new CustomEvent('await-c3-ui-window-open', {
						detail: { uiWindow: null, options: 'preview' }
					})
				);

				return true
			}
		}

		return false
	}


	render() {

		if(this.props.previewing !== true) {

			return <Button 
				className={`square exit preview`} 
				icon={<CloseIcon />}
				onMouseDown={e=>{

					// remove selection when going into preview
					FRONTEND_DATA.contentWindow.getSelection().removeAllRanges();

					if( this.delayPreviewForCommerceSave() ){ return }

					this.props.history.push('/preview');


				}}
				tooltip={this.isMac ? 'Preview (Cmd+\)' : null}
			/>

		} else {

			return (
				<button-area 
					id="preview-button-area"
				>
					<div className="button-area">
						{/* Print a style tag to hide the admin bars */}
						<style>{`
							#device-viewport {
								--admin-margin: 0px;
							}
						`}</style>


						{/* <Button  */}
						{/* 	id="preview-button" */}
						{/* 	className={`${this.props.contactForm.inited ? 'disabled' : ''}`} */}
						{/* 	icon={ */}
						{/* 		<svg x="0px" y="0px" viewBox="0 0 40 40"> */}
						{/* 			<path d="M21.86441,8.89059L10.78118,20.0509l11.08323,11.11086L20.02618,33L7,20.0509L19.9491,7L21.86441,8.89059z"></path> */}
						{/* 		</svg> */}
						{/* 	} */}
						{/* 	onMouseDown={e=>{ */}
						{/* 		if( this.props.contactForm.inited ){ return } */}
						{/* 		this.navigateToAdmin(); */}
						{/* 	}} */}
						{/* 	// tooltip="Back to Edit" */}
						{/* 	// toolTipOnButton={true} */}
						{/* /> */}

						<HotKey shortcut="escape" boundTo="all" callback={() => {
							this.navigateToAdmin();
						}} />

						{ this.isMac ? ( 

							<HotKey shortcut="cmd+escape" boundTo="all" callback={() => {
								this.navigateToAdmin();
							}} />

						) : ( null )}

					</div> {/* relative pos container */}
				</button-area>
			)

		}

	}

	navigateToAdmin = () => {

		if(this.props.allowAdminAccess !== true) {
			return;
		}

		const scrollPos = FRONTEND_DATA.contentWindow.scrollY;

		const originalPageStillActive = this.props.renderedPages.find(page => page.id === this.pidToReturnTo);

		if(!originalPageStillActive) {
			
			const state = store.getState();

			// page isn't rendered anymore. Edit the page currently viewed.
			this.pidToReturnTo = state.frontendState.activePID;

			if(state.sets.byId[this.pidToReturnTo] !== undefined) {
				// active PID is a set. Load the first rendered page if possible
				const firstRenderedPage = FRONTEND_DATA.contentWindow.document.querySelector('.content .pages .page');
				this.pidToReturnTo = firstRenderedPage ? firstRenderedPage.id : null;
			}
		}


		if(this.pidToReturnTo) {
			this.props.history.push(`/${this.pidToReturnTo}`)
		} else {
			this.props.history.push('/')
		}

		requestAnimationFrame(() => {
			// restore the scroll position after we close preview
			FRONTEND_DATA.contentWindow.scrollTo(0, scrollPos);
		});
	}

}

function mapReduxStateToProps(state, ownProps) {

	return {
		mobileHomepageId: state.site.mobile_homepage_id,
		homepageId: state.site.homepage_id,
		PIDBeingEdited: state.frontendState.PIDBeingEdited,
		activePID: state.frontendState.activePID,
		renderedPages: state.frontendState.renderedPages,
		contactForm: state.frontendState.contactForm,
		shopId: state.site.shop_id,
		commerceWindowOpen: state.uiWindows.byId?.['commerce-window'] ? true : false,
	};

}

export default withRouter(connect(
	mapReduxStateToProps
)(PreviewButton))